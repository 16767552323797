import axiosInstance from "./axiosInstance";
import {CreateAddOnFormType} from "../types/ComponentProps";

const editAddOn = async (
  addOnId: number,
  addOnDetails: CreateAddOnFormType
) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  try {
    const response = await axiosInstance.patch(
      `/api/addOn/${addOnId}`,
      {
        addonName: addOnDetails?.addonName,
        price: addOnDetails?.price,
        addonDescription: addOnDetails?.addonDescription,
        addonImage: null,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating users. Try");
  }
};

export default editAddOn;
