import {useMemo} from "react";
import {Link} from "react-router-dom";
import Spinner from "../spinner/Spinner";
import useGetMultipleProfilePicture from "../../hooks/useGetMultipleProfilePicture";
import {
  PostCardMessageSectionProps,
  HashTagPropsCardList,
} from "../../types/ComponentProps";
import PostCardStyles from "./PostCardStyles.module.css";

const PostCardMessage = ({
  message,
  recipients,
  hashTagIds,
  badgeId,
  badgeData,
  badgeDataLoading,
}: PostCardMessageSectionProps) => {
  const rec = (recipients || []).map((data) => data?.id).filter(Boolean);

  const {isLoading, multipleProfilePictureData} =
    useGetMultipleProfilePicture(rec);

  const getRandomImages = (images: any[], count: number) => {
    const shuffled = images.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomImages = useMemo(() => {
    return getRandomImages(multipleProfilePictureData?.profileImages || [], 3);
  }, [multipleProfilePictureData?.profileImages]);

  const isUrl = (str: string) => {
    const urlRegex =
      /^(https?:\/\/[^\s]+|www\.[^\s]+\.[a-z]{2,}|[^\s]+\.[a-z]{2,})(?=\s|$)/i;
    return urlRegex.test(str);
  };

  const normalizeUrl = (str: string) => {
    if (str.startsWith("www.")) {
      return `https://${str}`;
    }
    if (!str.startsWith("http") && str.match(/\.[a-z]{2,}$/i)) {
      return `https://${str}`;
    }
    return str;
  };

  const renderMessageContent = (message: string) => {
    if (!message) return null;

    const parts = message.split(/(https?:\/\/[^\s]+|www\.[^\s]+\.[a-z]{2,})/i);

    return parts.map((part, index) =>
      isUrl(part) ? (
        <a
          key={index}
          href={normalizeUrl(part)}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: "#fff"}}
          className={PostCardStyles.messageLink}>
          {part}
        </a>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <div>
      {badgeId ? (
        <div className={PostCardStyles.badgeSection}>
          {badgeDataLoading ? (
            <Spinner variant="small-spinner" alignSpin="center-spinner" />
          ) : (
            <>
              <img src={badgeData?.imageURL} alt="Badge" />
              <h1>{badgeData?.badgeName}</h1>
            </>
          )}

          <div
            className={PostCardStyles.multipleImageSelection}
            style={
              randomImages.length === 3
                ? {width: "70px"}
                : randomImages.length === 2
                ? {width: "56px"}
                : randomImages.length === 1
                ? {width: "36px"}
                : undefined
            }>
            {isLoading ? (
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            ) : (
              randomImages.map((data: any, index: number) => (
                <img src={data?.profileImage} alt="" key={index} />
              ))
            )}
          </div>
        </div>
      ) : null}

      <p className={PostCardStyles.messageBlockSection}>
        {renderMessageContent(message || "")}
        {recipients?.length === 0
          ? null
          : recipients?.map((data, index) => {
              return (
                <Link
                  to={`/profile/${data?.id}`}
                  className={PostCardStyles.linkTaggedUsers}
                  key={index}>
                  <span
                    className={
                      PostCardStyles.recipientListBlk
                    }>{`@${data?.preferredName}`}</span>
                </Link>
              );
            })}
        {!hashTagIds
          ? null
          : hashTagIds?.map((data: HashTagPropsCardList, index: number) => {
              return (
                <span className={PostCardStyles.spanHashTagSection} key={index}>
                  #{data?.hashTagName}
                </span>
              );
            })}
      </p>
    </div>
  );
};

export default PostCardMessage;
