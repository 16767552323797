import {useState, useEffect} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import createAddOn from "../../api/createAddOn";
import deleteAddOn from "../../api/deleteAddOn";
import editAddOn from "../../api/editAddOn";
import useGetAllAddOns from "../../hooks/useGetAllAddOns";
import Spinner from "../spinner/Spinner";
import {
  AddOnProps,
  CreateAddOnFormType,
  ErrorHandlerAddOn,
} from "../../types/ComponentProps";
import AddOnsListStyles from "./AddOnsListStyles.module.css";
import CreateAddOnModal from "./CreateAddOnModal";
import DeleteAddOnModal from "./DeleteAddOnModal";
import UpdateAddOnModal from "./UpdateAddOnModal";

// Modal State Type
type ModalState = {
  type: "create" | "edit" | "delete" | null;
  addonId?: number;
};

const AddOnsList: React.FC = () => {
  const queryClient = useQueryClient();
  const {ref, inView} = useInView();

  const {
    getAllAddOnsData,
    isLoadingGetAllAddOns,
    fetchNextPageGetAllAddOns,
    hasNextPageGetAllAddOns,
    isFetchingNextPageGetAllAddOns,
  } = useGetAllAddOns();

  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [modalState, setModalState] = useState<ModalState>({type: null});
  const [isRefetching, setIsRefetching] = useState(false);

  const [createAddOnForm, setCreateAddOnForm] = useState<CreateAddOnFormType>({
    addonName: "",
    addonImage: null,
    addonDescription: "",
    price: 0,
  });

  const [createAddOnIsLoading, setCreateAddOnIsLoading] = useState(false);
  const [errorHandler, setErrorHandler] = useState<ErrorHandlerAddOn>({
    success: true,
    message: "",
    field: "",
  });

  useEffect(() => {
    if (inView && hasNextPageGetAllAddOns) {
      fetchNextPageGetAllAddOns();
    }
  }, [inView, fetchNextPageGetAllAddOns, hasNextPageGetAllAddOns]);

  const addOnList =
    getAllAddOnsData?.pages.flatMap((page: any) => page.addons) || [];

  // React Query Mutations
  const createMutation = useMutation({
    mutationFn: () => createAddOn(createAddOnForm),
    onSuccess: async () => {
      setIsRefetching(true);
      await queryClient.invalidateQueries({queryKey: ["getAllAddOns"]});
      setModalState({type: null});
      setCreateAddOnIsLoading(false);
      setIsRefetching(false);
      setCreateAddOnForm({
        addonName: "",
        addonImage: null,
        addonDescription: "",
        price: 0,
      });
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateAddOnIsLoading(false);
      console.error("Error creating product:", error.message);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (productId: number) => deleteAddOn(productId),
    onSuccess: async () => {
      setIsRefetching(true);
      await queryClient.invalidateQueries({queryKey: ["getAllAddOns"]});
      setModalState({type: null});
      setCreateAddOnIsLoading(false);
      setIsRefetching(false);
      setCreateAddOnForm({
        addonName: "",
        addonImage: null,
        addonDescription: "",
        price: 0,
      });
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateAddOnIsLoading(false);
      console.error("Error deleting product:", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (addOnId: number) => editAddOn(addOnId, createAddOnForm),
    onSuccess: async () => {
      setIsRefetching(true);
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ["getAllAddOns"]}),
        queryClient.invalidateQueries({
          queryKey: ["getAddOnById", Number(modalState?.addonId)],
        }),
      ]);
      setModalState({type: null});
      setCreateAddOnIsLoading(false);
      setIsRefetching(false);
      setCreateAddOnForm({
        addonName: "",
        addonImage: null,
        addonDescription: "",
        price: 0,
      });
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateAddOnIsLoading(false);
      console.error("Error creating product:", error.message);
    },
  });

  // Handlers

  const createAddOnSubmitHandler = () => {
    const requiredFields: Array<keyof typeof createAddOnForm> = [
      "addonName",
      "addonDescription",
      "price",
    ];

    for (const field of requiredFields) {
      if (!createAddOnForm[field]) {
        setErrorHandler({
          success: false,
          message: `${field.replace(/([A-Z])/g, " $1")} is required.`,
          field,
        });
        return;
      }
    }
    setErrorHandler({success: true, message: "", field: ""});
    setCreateAddOnIsLoading(true);
    createMutation.mutate();
  };

  const updateAddOnSubmitHandler = () => {
    const requiredFields: Array<keyof typeof createAddOnForm> = [
      "addonName",
      "addonDescription",
      "price",
    ];

    for (const field of requiredFields) {
      if (!createAddOnForm[field]) {
        setErrorHandler({
          success: false,
          message: `${field.replace(/([A-Z])/g, " $1")} is required.`,
          field,
        });
        return;
      }
    }
    setErrorHandler({success: true, message: "", field: ""});
    if (!modalState.addonId) return;
    updateMutation.mutate(modalState?.addonId);
    setCreateAddOnIsLoading(true);
  };

  const onDeleteSubmitHandler = () => {
    if (modalState.type === "delete" && modalState.addonId) {
      setCreateAddOnIsLoading(true);
      deleteMutation.mutate(modalState.addonId);
    }
  };

  const onShowDropDownUpdateHandler = (addOnId: number) => {
    setActiveDropdown(activeDropdown === addOnId ? null : addOnId);
  };

  const handleCloseModal = () => {
    setCreateAddOnForm({
      addonName: "",
      addonImage: null,
      addonDescription: "",
      price: 0,
    });
    setActiveDropdown(null);
    setErrorHandler({success: true, message: "", field: ""});
  };

  return (
    <div className={AddOnsListStyles.manageAddOnListBlk}>
      {/* Modals */}
      {modalState.type === "create" && (
        <CreateAddOnModal
          createAddOnSubmitHandler={createAddOnSubmitHandler}
          createAddOnIsLoading={createAddOnIsLoading}
          createAddOnForm={createAddOnForm}
          inputChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCreateAddOnForm((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
          onCloseClickHandler={() => setModalState({type: null})}
          errorHandler={errorHandler}
          setCreateAddOnForm={setCreateAddOnForm}
        />
      )}
      {modalState.type === "delete" && modalState.addonId && (
        <DeleteAddOnModal
          setShowDeleteModal={() => setModalState({type: null})}
          onDeleteSubmitHandler={onDeleteSubmitHandler}
          createAddOnIsLoading={createAddOnIsLoading}
        />
      )}

      {modalState.type === "edit" && (
        <UpdateAddOnModal
          activeProductId={modalState?.addonId}
          updateAddOnSubmitHandler={updateAddOnSubmitHandler}
          createAddOnIsLoading={createAddOnIsLoading}
          createAddOnForm={createAddOnForm}
          inputChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCreateAddOnForm((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
          onCloseClickHandler={() => {
            handleCloseModal();
            setActiveDropdown(null);
            setModalState({type: null});
          }}
          errorHandler={errorHandler}
          setCreateAddOnForm={setCreateAddOnForm}
        />
      )}

      {/* Header */}
      <div
        className={AddOnsListStyles.manageProductBlkTitle}
        style={addOnList.length > 10 ? {overflowY: "scroll"} : {}}>
        <h1>Add-ons</h1>
        <button
          className={AddOnsListStyles.manageProductBlkTitleButton}
          onClick={() => setModalState({type: "create"})}>
          <i className="fa-solid fa-plus"></i> Create new add-on
        </button>
      </div>
      {/* Table Headers */}
      <div className={AddOnsListStyles.headerTableBlkHeaderAddOnList}>
        {["Add-on Name", "Add-on Description", "Price", "Actions"].map(
          (header) => (
            <div key={header}>
              <p>{header}</p>
            </div>
          )
        )}
      </div>
      {/* Product List */}
      <div className={AddOnsListStyles.addOnListMainContainer}>
        {isLoadingGetAllAddOns || isRefetching ? (
          <div style={{display: "block", padding: "10px 0"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : addOnList.length === 0 ? (
          <p className={AddOnsListStyles.notAvailableBlkTxt}>
            No add-ons are currently available
          </p>
        ) : (
          addOnList.map((addOn: AddOnProps, index: number) => (
            <div className={AddOnsListStyles.rowBlkAddOnList} key={index}>
              <div>
                <p>{addOn?.addonName}</p>
              </div>
              <div>
                <p>{addOn?.addonDescription}</p>
              </div>
              <div>
                <p>{addOn?.price}</p>
              </div>
              <div>
                <div style={{position: "relative"}}>
                  {activeDropdown === Number(addOn?.addonId) && (
                    <div
                      className={AddOnsListStyles.dropDownEdit}
                      onMouseLeave={() => {
                        setActiveDropdown(null);
                      }}>
                      <span
                        onClick={() =>
                          setModalState({
                            type: "edit",
                            addonId: Number(addOn?.addonId),
                          })
                        }>
                        Edit
                      </span>
                      <span
                        onClick={() =>
                          setModalState({
                            type: "delete",
                            addonId: Number(addOn?.addonId),
                          })
                        }>
                        Delete
                      </span>
                    </div>
                  )}
                  <i
                    className="fa-solid fa-ellipsis"
                    onClick={() =>
                      onShowDropDownUpdateHandler(Number(addOn?.addonId))
                    }></i>
                </div>
              </div>
            </div>
          ))
        )}
        <div ref={ref} style={{height: "1px"}}></div>
        {isFetchingNextPageGetAllAddOns && (
          <div style={{display: "block", padding: "20px 0px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOnsList;
