import {useMemo} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import deleteNotification from "../../api/deleteNotification";
import useGetPostById from "../../hooks/useGetPostById";
import Spinner from "../spinner/Spinner";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import useGetUserDetailsById from "../../hooks/useGetUserDetailsById";
import useGetBadgeById from "../../hooks/useGetBadgeById";
import useGetMultipleProfilePicture from "../../hooks/useGetMultipleProfilePicture";
import {
  NotificationModalProps,
  HashTagPropsCardList,
  CommentPostCardListProps,
} from "../../types/ComponentProps";
import PostCardFooter from "../postCard/PostCardFooter";
import CommentPost from "../commentsModal/CommentPost";
import CommentPostCard from "../commentsModal/CommentPostCard";
import {getLevel} from "../../utils/getLevel";
import dateFormatter from "../../utils/dateFormat";
import NotificationModalStyles from "./NotificationModalStyles.module.css";
import useGetAllPosts from "../../hooks/useGetAllPosts";
import useGetPostCommentsById from "../../hooks/useGetPostCommentsById";

const NotificationModal = ({
  setNotificationModal,
  setSelectedPostNotification,
  selectedPostNotification,
  selectedNotificationId,
  socket,
}: NotificationModalProps) => {
  const queryClient = useQueryClient();

  const {getPostByIdData, isLoading: isPostLoading} = useGetPostById(
    selectedPostNotification ?? 0
  );

  const {refetch} = useGetAllPosts();
  const {
    refetch: commentPostRefetch,
    getPostComments,
    isLoading: getPostCommentsisLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetPostCommentsById(selectedPostNotification ?? 0);

  const userId = getPostByIdData?.post?.userId;

  const {getProfileImage, isLoading: isProfileImageLoading} =
    useGetProfilePicture(userId ?? 0);

  const {userDetailsById, isLoading: userDetailsLoading} =
    useGetUserDetailsById(userId ?? 0);

  const {getBadgeByIdData, isLoading: badgeDataLoading} = useGetBadgeById(
    getPostByIdData?.post?.badgeId
  );

  // Delete Mutation
  const deleteNotificationMutation = useMutation({
    mutationFn: (notificationId: any) =>
      deleteNotification(Number(notificationId)),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({queryKey: ["getAllPosts"]});
        await refetch();
        await queryClient.invalidateQueries({queryKey: ["getPostComments"]});
        await commentPostRefetch();
        await queryClient.invalidateQueries({
          queryKey: ["getNotificationById"],
        });
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
      }
    },
    onError: (error: Error) => {
      console.error("Error comment deletion:", error);
    },
  });

  const onClickClosHandler = async () => {
    if (setNotificationModal && setSelectedPostNotification) {
      setNotificationModal(false);
      try {
        await deleteNotificationMutation.mutateAsync(selectedNotificationId);
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    }
  };

  const levelInfo = getLevel(userDetailsById?.user?.user_point?.points ?? 0);
  const levelIndex = levelInfo?.index != null ? levelInfo.index + 1 : null;
  const rec = (getPostByIdData?.post?.recipients || [])
    .map((data: any) => data?.id)
    .filter(Boolean);

  const {isLoading, multipleProfilePictureData} =
    useGetMultipleProfilePicture(rec);

  const getRandomImages = (images: any[], count: number) => {
    const shuffled = images.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomImages = useMemo(() => {
    return getRandomImages(multipleProfilePictureData?.profileImages || [], 3);
  }, [multipleProfilePictureData?.profileImages]);

  if (
    isPostLoading ||
    (userId && isProfileImageLoading) ||
    userDetailsLoading
  ) {
    return (
      <div className={NotificationModalStyles.modalContainer}>
        <div className={NotificationModalStyles.modalMainBlk}>
          <Spinner variant="medium-spinner" alignSpin="center-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div className={NotificationModalStyles.modalContainer}>
      <div className={NotificationModalStyles.modalMainBlk}>
        <i
          onClick={onClickClosHandler}
          className={`fa-solid fa-xmark ${NotificationModalStyles.closeNotificationModalIcon}`}></i>
        {!getPostByIdData?.post?.badgeId ? (
          <div className={NotificationModalStyles.postShownSectionBlk}>
            <div className={NotificationModalStyles.headerSectionStyles}>
              {getPostByIdData?.post?.pointsGiven ? (
                <div className={NotificationModalStyles.pointsGivenPill}>
                  +{getPostByIdData?.post?.pointsGiven}
                </div>
              ) : null}

              <div className={NotificationModalStyles.avatarSection}>
                <img alt="profile" src={getProfileImage?.profileImage} />
              </div>

              <div className={NotificationModalStyles.preferredNameSection}>
                <div
                  className={
                    NotificationModalStyles.preferredNameSectionFlexBox
                  }>
                  <h3>{userDetailsById?.user?.preferredName}</h3>
                  <div className={NotificationModalStyles.pronounSection}>
                    <span>
                      {userDetailsById?.user?.user_profile?.pronouns
                        ? userDetailsById?.user?.user_profile?.pronouns
                        : null}
                    </span>
                  </div>
                </div>
                {userDetailsById?.user?.isAdmin ? (
                  <div className={NotificationModalStyles.levelSectionCard}>
                    <p>Administrator</p>
                  </div>
                ) : (
                  <div className={NotificationModalStyles.levelSectionCard}>
                    <p>
                      Lv. {levelIndex} - {levelInfo?.level}
                    </p>
                  </div>
                )}
              </div>

              <div
                className={NotificationModalStyles.dateSection}
                style={
                  userDetailsById?.user?.preferredName
                    ? {marginLeft: "auto"}
                    : {}
                }>
                <span>{dateFormatter(getPostByIdData?.post?.createdAt)}</span>
              </div>
            </div>
            <div className={NotificationModalStyles.scrollSectionpostShownBlk}>
              <div className={NotificationModalStyles.badgeSection}>
                <p
                  className={
                    NotificationModalStyles.badgeSectionParagraphMessage
                  }>
                  {getPostByIdData?.post?.message}
                  {getPostByIdData?.post?.recipients?.length === 0
                    ? null
                    : getPostByIdData?.post?.recipients?.map(
                        (data: any, index: number) => {
                          return (
                            <span
                              key={index}
                              className={
                                NotificationModalStyles.recipientListBlk
                              }>{`@${data?.preferredName}`}</span>
                          );
                        }
                      )}
                  {!getPostByIdData?.post?.hashTagIds
                    ? null
                    : getPostByIdData?.post?.hashTagIds?.map(
                        (data: HashTagPropsCardList, index: number) => {
                          return (
                            <span
                              className={
                                NotificationModalStyles.spanHashTagSection
                              }
                              key={index}>
                              #{data?.hashTagName}
                            </span>
                          );
                        }
                      )}
                </p>
              </div>
              {getPostByIdData?.post?.imageURL ? (
                <img
                  alt=""
                  className={NotificationModalStyles.uploadedImageSectionBlk}
                  src={getPostByIdData?.post?.imageURL}
                />
              ) : null}
            </div>

            <div className={NotificationModalStyles.likeContainer}>
              <span className="fa-solid fa-thumbs-up"></span>
              {!getPostByIdData?.post?.likes?.length ? (
                <p>No one reacted to this post</p>
              ) : (
                <p>
                  {getPostByIdData?.post?.likes?.length} people like this post
                </p>
              )}
            </div>

            <PostCardFooter post={getPostByIdData?.post} socket={socket} />

            {getPostComments?.pages?.flatMap((page) => page.comments).length !==
              0 && <hr className={NotificationModalStyles.hrStyles} />}
            <div className={NotificationModalStyles.scrollCommentListSection}>
              {getPostCommentsisLoading ? (
                <Spinner variant="medium-spinner" alignSpin="center-spinner" />
              ) : getPostComments?.pages?.flatMap((page) => page.comments)
                  .length === 0 ? null : (
                getPostComments?.pages?.flatMap((page) =>
                  page.comments.map(
                    (data: CommentPostCardListProps["comments"]) => (
                      <CommentPostCard key={data.commentId} data={data} />
                    )
                  )
                )
              )}
              {isFetchingNextPage ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : null}
              {getPostComments?.pages[0]?.pagination?.totalItems > 3 &&
                hasNextPage && (
                  <div
                    className={NotificationModalStyles.showAllCommentsSection}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        fetchNextPage();
                      }}
                      disabled={isFetchingNextPage}>
                      {isFetchingNextPage ? null : "View previous comments"}
                    </button>
                  </div>
                )}
            </div>
          </div>
        ) : (
          <div className={NotificationModalStyles.postShownSectionBlk}>
            <div className={NotificationModalStyles.headerSectionStyles}>
              {getPostByIdData?.post?.pointsGiven ? (
                <div className={NotificationModalStyles.pointsGivenPill}>
                  +{getPostByIdData?.post?.pointsGiven}
                </div>
              ) : null}

              <div
                className={NotificationModalStyles.dateSection}
                style={
                  userDetailsById?.user?.preferredName
                    ? {marginLeft: "auto"}
                    : {}
                }>
                <span>{dateFormatter(getPostByIdData?.post?.createdAt)}</span>
              </div>
            </div>

            <div className={NotificationModalStyles.badgeSection}>
              {badgeDataLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                <>
                  <img src={getBadgeByIdData?.badge?.imageURL} alt="" />
                  <h1>{getBadgeByIdData?.badge?.badgeName}</h1>
                </>
              )}
              <div
                className={NotificationModalStyles.multipleImageSelection}
                style={
                  randomImages.length === 3
                    ? {width: "70px"}
                    : randomImages.length === 2
                    ? {width: "56px"}
                    : randomImages.length === 1
                    ? {width: "36px"}
                    : undefined
                }>
                {isLoading ? (
                  <Spinner
                    variant="medium-spinner"
                    alignSpin="center-spinner"
                  />
                ) : (
                  randomImages.map((data: any, index: number) => (
                    <img
                      className={NotificationModalStyles.multiImageShow}
                      src={data?.profileImage}
                      alt=""
                      key={index}
                    />
                  ))
                )}
              </div>
            </div>

            <div className={NotificationModalStyles.messageBlockBadgeSection}>
              <p>
                {getBadgeByIdData?.badge?.message}
                {getBadgeByIdData?.badge?.recipients?.length === 0
                  ? null
                  : getBadgeByIdData?.badge?.recipients?.map(
                      (data: any, index: number) => {
                        return (
                          <span
                            key={index}
                            className={
                              NotificationModalStyles.recipientListBlk
                            }>{`@${data?.preferredName}`}</span>
                        );
                      }
                    )}
                {!getBadgeByIdData?.badge?.hashTagIds
                  ? null
                  : getBadgeByIdData?.badge?.hashTagIds?.map(
                      (data: HashTagPropsCardList, index: number) => {
                        return (
                          <span
                            className={
                              NotificationModalStyles.spanHashTagSection
                            }
                            key={index}>
                            #{data?.hashTagName}
                          </span>
                        );
                      }
                    )}
              </p>
            </div>

            <div className={NotificationModalStyles.likeContainer}>
              <span className="fa-solid fa-thumbs-up"></span>
              {!getPostByIdData?.post?.likes?.length ? (
                <p>No one reacted to this post</p>
              ) : (
                <p>
                  {getPostByIdData?.post?.likes?.length} people like this post
                </p>
              )}
            </div>

            {getPostComments?.pages?.flatMap((page) => page.comments).length !==
              0 && <hr className={NotificationModalStyles.hrStyles} />}
            <div className={NotificationModalStyles.scrollCommentListSection}>
              {getPostCommentsisLoading ? (
                <Spinner variant="medium-spinner" alignSpin="center-spinner" />
              ) : getPostComments?.pages?.flatMap((page) => page.comments)
                  .length === 0 ? null : (
                getPostComments?.pages?.flatMap((page) =>
                  page.comments.map(
                    (data: CommentPostCardListProps["comments"]) => (
                      <CommentPostCard key={data.commentId} data={data} />
                    )
                  )
                )
              )}
              {isFetchingNextPage ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : null}
              {getPostComments?.pages[0]?.pagination?.totalItems > 3 &&
                hasNextPage && (
                  <div
                    className={NotificationModalStyles.showAllCommentsSection}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        fetchNextPage();
                      }}
                      disabled={isFetchingNextPage}>
                      {isFetchingNextPage ? null : "View previous comments"}
                    </button>
                  </div>
                )}
            </div>
          </div>
        )}
        <CommentPost post={getPostByIdData?.post} socket={socket} />
      </div>
    </div>
  );
};

export default NotificationModal;
