import {useState, useRef} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {LikeButtonProps} from "../../types/ComponentProps";
import LikeButtonStyle from "./LikeButtonStyles.module.css";

import LikeReaction from "../../assets/img/reactions/like.svg";
import LoveReaction from "../../assets/img/reactions/love.svg";
import LaughReaction from "../../assets/img/reactions/laugh.svg";
import SanaOlReaction from "../../assets/img/reactions/sanaol.svg";
import LodiReaction from "../../assets/img/reactions/lodi.svg";

import Spinner from "../spinner/Spinner";

const reactions = [
  {type: "Like", icon: LikeReaction},
  {type: "Love", icon: LoveReaction},
  {type: "Sanaol", icon: SanaOlReaction},
  {type: "Laugh", icon: LaughReaction},
  {type: "Lodi", icon: LodiReaction},
];

const reactionIcons = new Map(reactions.map(({type, icon}) => [type, icon]));

const LikeButton = ({
  isLiked,
  post,
  onLikeButtonClickHandler,
  likeIsLoading,
  userDetails,
}: LikeButtonProps) => {
  const [showInteractions, setShowInteractions] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const userReaction =
    post.likes?.find(({id}) => id === userDetails?.id) ?? null;
  const activeReaction = reactions.findIndex(
    ({type}) => type === userReaction?.reaction
  );

  const getReactionIcon = (reaction?: string) =>
    reaction && reactionIcons.get(reaction);

  const handleMouseEnter = () => {
    if (!likeIsLoading) {
      clearTimeout(hoverTimeoutRef.current!);
      setShowInteractions(true);
    }
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => setShowInteractions(false), 200);
  };

  const handleClick = () => {
    if (!likeIsLoading) {
      onLikeButtonClickHandler?.(activeReaction === -1 ? 0 : activeReaction);
      setShowInteractions(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();
    if (!showInteractions) {
      setShowInteractions(true);
    } else {
      handleClick();
    }
  } else if (event.key === 'Escape' && showInteractions) {
    setShowInteractions(false);
  }
};

  return (
    <div tabIndex={0}
      className={`${LikeButtonStyle.interactionContainer} ${
        isLiked ? LikeButtonStyle.isLiked : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyPress}
            aria-expanded={showInteractions}
      aria-haspopup="true"
      aria-label={`React to post${userReaction ? `: Current reaction is ${userReaction.reaction}` : ''}`}>
      <AnimatePresence>
        {showInteractions && (
          <motion.div tabIndex={0}
          role="menu"
          aria-label="Reaction Options"
            initial={{opacity: 0, scale: 0.8}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 0.8}}
            transition={{duration: 0.3, ease: "easeInOut"}}
            className={`${LikeButtonStyle.interactionIconContainer} ${LikeButtonStyle.showInteractions}`}>
            {reactions.map(({type, icon}, index) => (
              <motion.div tabIndex={0}
              role="menuitem"
                key={type}
                whileHover={{scale: 1.3, y: -25}}
                transition={{type: "spring", stiffness: 300}}
                className={LikeButtonStyle.reactionWrapper}
                onClick={() => {
                  setShowInteractions(false);
                  onLikeButtonClickHandler?.(index);
                }}
                                aria-label={`${type}`}>
                <motion.img
                  src={icon}
                  alt={type}
                  className={LikeButtonStyle.reactionIcon}
                />
                <motion.p
                  initial={{opacity: 0, y: 5}}
                  animate={{opacity: 1, y: 0}}
                  exit={{opacity: 0, y: 5}}
                  transition={{duration: 0.2}}
                  className={LikeButtonStyle.reactionLabel}>
                  {type}
                </motion.p>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={LikeButtonStyle.textButtonBlock} onClick={handleClick}>
        {likeIsLoading ? (
          <Spinner alignSpin="center-spinner" variant="small-spinner" />
        ) : userReaction ? (
          <>
            {getReactionIcon(userReaction.reaction) && (
              <img
                src={getReactionIcon(userReaction.reaction)!}
                className={LikeButtonStyle.buttonImage}
                alt={userReaction.reaction}
              />
            )}
            <span>{userReaction.reaction}</span>
          </>
        ) : (
          <>
            <i className="fa-solid fa-thumbs-up"></i>
            <span>{isLiked ? "Liked" : "Like"}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default LikeButton;
