import axiosInstance from "./axiosInstance";

const getAddOnById = async (addOnId: number, pageParam: number = 1) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(`/api/getAddonById/${addOnId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching posts", error);
    throw new Error("Error fetching user posts.");
  }
};

export default getAddOnById;
