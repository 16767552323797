import {useEffect, useState} from "react";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import useGetUserDetailsById from "../../hooks/useGetUserDetailsById";
import useGetMultipleBadgesByIds from "../../hooks/useGetMultipleBadgesById";
import LevelBlock from "../level/LevelBlock";
import Spinner from "../spinner/Spinner";
import {
  LevelBlockProps,
  PostCardDetailsProps,
  BadgePropsDetails,
} from "../../types/ComponentProps";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import MyProfileStyles from "./MyProfileStyles.module.css";

const PublicPersonalDetails = ({userDetails}: any) => {
  const {getProfileImage, error, isLoading, isFetching} = useGetProfilePicture(
    userDetails?.id ?? 0
  );
  const {userDetailsById} = useGetUserDetailsById(userDetails?.id ?? 0);
  const [badgeIds, setBadgeIds] = useState<number[]>([]);
  const {multipleBadgesData, isLoading: badgesLoading} =
    useGetMultipleBadgesByIds(badgeIds);
  const splitString = (str: string) => {
    return str.length > 30 ? `${str.substring(0, 30)}...` : str;
  };
  const pointObject: LevelBlockProps["data"] = {
    success: true,
    points: {
      pointsId: userDetailsById?.user?.user_point?.pointsId ?? 0,
      userId: userDetailsById?.user?.user_point?.userId ?? 0,
      points: userDetailsById?.user?.user_point?.points ?? 0,
      giveablePoints: userDetailsById?.user?.user_point?.giveablePoints ?? 0,
    },
  };
  useEffect(() => {
    const updatedBadges =
      userDetailsById?.user?.user_profile?.badges?.map(
        (item: PostCardDetailsProps) => item?.badgeId
      ) || [];
    setBadgeIds(updatedBadges);
  }, [userDetailsById?.user?.user_profile?.badges]);
  return (
    <div className={MyProfileStyles.personalDetailsSectionBlk}>
      <div className={MyProfileStyles.headerPersonalDetailsSectionBlk}>
        <div>
          {isLoading || isFetching ? (
            <Spinner
              addedStyles={{marginTop: "6px"}}
              variant="small-spinner"
              alignSpin="center-spinner"
            />
          ) : error ? (
            <img
              className={MyProfileStyles.avatarImageProfileSection}
              alt=""
              src={DefaultImage}
            />
          ) : (
            <img
              className={MyProfileStyles.avatarImageProfileSection}
              alt=""
              src={getProfileImage?.profileImage}
            />
          )}
        </div>
        <div></div>
      </div>
      {isLoading ? (
        <Spinner variant="medium-spinner" alignSpin="center-spinner" />
      ) : (
        <>
          <div className={MyProfileStyles.personalDetailsContactSection}>
            <div className={MyProfileStyles.nameSectionBlk}>
              <div>
                <h1>{`${userDetailsById?.user?.firstName} ${userDetailsById?.user?.lastName}`}</h1>
              </div>
              {userDetailsById?.user?.user_profile?.pronouns && (
                <div>
                  <h3>{userDetailsById?.user?.user_profile?.pronouns}</h3>
                </div>
              )}
            </div>
            <span className={MyProfileStyles.jobTitleSectionBlk}>
              {userDetailsById && splitString(userDetailsById?.user?.jobTitle)}
            </span>
            <p>{userDetailsById?.user?.email}</p>
            {userDetailsById?.user?.mobilePhone && (
              <p>+63{userDetailsById?.user?.mobilePhone}</p>
            )}
          </div>
          {userDetailsById?.user?.user_profile?.bio && (
            <>
              <hr />
              <div className={MyProfileStyles.personalDetailsBioSection}>
                <p>{userDetailsById?.user?.user_profile?.bio}</p>
              </div>
            </>
          )}
          {!userDetails?.isAdmin && (
            <div style={{marginTop: "10px"}}>
              <LevelBlock data={pointObject} />
            </div>
          )}
          {!userDetails?.isAdmin && (
            <div
              className={MyProfileStyles.scrollDivBadgesSectionProfile}
              style={{marginTop: "10px"}}>
              <h1>Badges</h1>
              <hr />
              {badgesLoading ? (
                <Spinner variant="medium-spinner" alignSpin="center-spinner" />
              ) : Array.isArray(multipleBadgesData?.badges) &&
                multipleBadgesData?.badges.length > 0 ? (
                multipleBadgesData?.badges.map(
                  (data: BadgePropsDetails, index: number) => (
                    <div
                      key={index}
                      className={MyProfileStyles.badgeListProfile}>
                      <div>
                        <img alt="Badge" src={data?.badgeImage} />
                      </div>
                      <div>
                        <h2>{data?.badgeName}</h2>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p
                  style={{
                    marginTop: "10px",
                    display: "block",
                    color: "#FECF0C",
                  }}>
                  No badges available
                </p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PublicPersonalDetails;
