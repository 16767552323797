import {useState, useEffect} from "react";
import {useNotification} from "../../context/NotificationContext";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import ProductDetailsModal from "../../components/productDetailsModal/ProductDetailsModal";
import ProductListContainer from "../../components/productListContainer/ProductListContainer";

const ShopPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeProduct, setActiveProduct] = useState<null | number>(null);

  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();

  const showModalClickHandler = (productId: number) => {
    setActiveProduct(productId);
    setShowModal(true);
  };

  const closeModalClickHandler = () => {
    setActiveProduct(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <ProductDetailsModal
          activeProduct={activeProduct}
          closeModalClickHandler={closeModalClickHandler}
        />
      )}

      <WithNavTemplate
        socket={!socketState ? null : socketState}
        setNotificationModal={setNotificationModal}
        setSelectedPostNotification={setSelectedPostNotification}
        setSelectedNotificationId={setSelectedNotificationId}>
        <div className="inside-page-container-blk  product-listcontainer">
          <div className="main-container-blk">
            <ProductListContainer
              showModalClickHandler={showModalClickHandler}
            />
          </div>
        </div>
      </WithNavTemplate>
    </>
  );
};

export default ShopPage;
