import {SetStateAction, Dispatch} from "react";
import Spinner from "../spinner/Spinner";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import ProductListStyles from "./ProductListStyles.module.css";

type DeleteProps = {
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  onDeleteSubmitHandler: () => void;
  createProductIsLoading?: boolean;
};

const DeleteProductModal = ({
  setShowDeleteModal,
  onDeleteSubmitHandler,
  createProductIsLoading,
}: DeleteProps) => {
  return (
    <div className={ProductListStyles.createProductModal}>
      <div className={ProductListStyles.deletePrompt}>
        <div className={ProductListStyles.deletePromptMainContainer}>
          <span
            className={`${ProductListStyles.xmark} fa-solid fa-xmark`}
            onClick={(e: any) => {
              if (createProductIsLoading) {
                return e.preventDefault();
              }
              setShowDeleteModal(false);
            }}></span>
          <img
            src={ModalIcon}
            alt="Modal icon"
            className={ProductListStyles.deleteIcon}
          />
          <h1>Are you sure you want to delete this product?</h1>
          <button onClick={onDeleteSubmitHandler}>
            {createProductIsLoading ? (
              <Spinner variant="small-spinner" alignSpin="center-spinner" />
            ) : (
              "YES"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProductModal;
