import {useQuery} from "@tanstack/react-query";

import getProductById from "../api/getProductById";

const useGetProductById = (productId: number) => {
  const {
    data: dataGetProductById,
    isLoading: isLoadingGetProductById,
    isError: isErrorGetProductById,
    error: errorGetProductById,
    refetch: refetchGetProductById,
  } = useQuery({
    queryKey: ["getProductById", productId],
    queryFn: () => getProductById(productId),
    refetchOnWindowFocus: false,
    enabled: productId > 0,
    staleTime: 0,
  });

  return {
    isLoadingGetProductById,
    isErrorGetProductById,
    dataGetProductById,
    errorGetProductById,
    refetchGetProductById,
  };
};

export default useGetProductById;
