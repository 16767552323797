import ProductList from "../../components/productList/ProductList";
import NavLinksAdmin from "../../components/navlinksAdmin/NavLinksAdmin";

const AdminCreateProduct = () => {
  return (
    <div className="inside-page-container-blk">
      <div className="admin-page-main-container">
        <NavLinksAdmin />
        <div>
          <ProductList />
        </div>
      </div>
    </div>
  );
};

export default AdminCreateProduct;
