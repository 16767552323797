import {SetStateAction, Dispatch} from "react";
import {ProductProps} from "../../types/ComponentProps";
import ProductCardStyles from "./ProductCardStyles.module.css";

type ProductTypeProps = {
  product: ProductProps;
  showModalClickHandler: (productId: number) => void;
};

const ProductCard = ({product, showModalClickHandler}: ProductTypeProps) => {
  const truncateText = (text: string, limit: number) =>
    text.length > limit ? text.slice(0, limit) + "..." : text;
  return (
    <div
      className={ProductCardStyles.productCardContainer}
      onClick={() => showModalClickHandler(Number(product?.productId))}>
      <img src={product?.imageURL} alt={product?.productName} />
      <div className={ProductCardStyles.flexBoxBlk}>
        <h2>{truncateText(product.productName, 15)}</h2>
        <p>{product?.price} coins</p>
      </div>
    </div>
  );
};

export default ProductCard;
