export const typeOptions = [
  {value: "Merch", label: "Merch"},
  {value: "Voucher", label: "Voucher"},
];

export const categoryOptions = [
  {value: "Clothing", label: "Clothing"},
  {value: "Food", label: "Food"},
  {value: "Electronics", label: "Electronics"},
];
