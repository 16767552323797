import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import createOrder from "../../api/createOrder";
import useGetProductById from "../../hooks/useGetProductById";
import Spinner from "../spinner/Spinner";
import {AddOnProps, TokenPayload} from "../../types/ComponentProps";
import ProductDetailsModalStyles from "./ProductDetailsModalStyles.module.css";
import OrderDetailAddress from "./OrderDetailAddress";
import useGetUserPoint from "../../hooks/useGetUserPoint";

type ProductDetailsModalProps = {
  activeProduct: number | null;
  closeModalClickHandler: () => void;
};

type Option = {
  id: string;
  title: string;
};

type Variant = {
  id: string;
  title: string;
  options: Option[];
};

type OrderFormState = {
  fullName: string;
  email: string;
  mobilePhone: string;
  city: string;
  province: string;
  postalCode: string;
  landmarks: string;
  orderDetails: {
    addOns?: AddOnProps[];
    variant?: Record<string, Option>;
    [key: string]: any;
  } | null;
};

const ProductDetailsModal = ({
  closeModalClickHandler,
  activeProduct,
}: ProductDetailsModalProps) => {
  const {data} = useGetUserPoint();
  const accessToken = localStorage.getItem("accessTokenGC");
  const userDetails = accessToken ? jwtDecode<TokenPayload>(accessToken) : null;
  const queryClient = useQueryClient();
  const {dataGetProductById, isLoadingGetProductById} = useGetProductById(
    Number(activeProduct)
  );

  const [ loadingOrder, setLoadingOrder] = useState({
    message: "",
    open: false,
  });

  const orderFormInitialState = {
    fullName: `${userDetails?.firstName} ${userDetails?.lastName}`,
    email: `${userDetails?.email}`,
    mobilePhone: "",
    city: "",
    province: "",
    postalCode: "",
    landmarks: "",
    orderDetails: null,
    orderStatus: 0,
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => setImageLoaded(true);
  const handleImageError = () => setImageLoaded(false);
  const [orderForm, setOrderForm] = useState<OrderFormState>(
    orderFormInitialState
  );
  const [selectedAddOns, setSelectedAddOns] = useState<AddOnProps[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<
    Record<string, Option | null>
  >({});
  const [addressPage, setAddressPage] = useState(false);

  useEffect(() => {
    if (dataGetProductById?.product) {
      setOrderForm((previous: OrderFormState) => ({
        ...previous,
        orderDetails: {
          ...dataGetProductById.product,
          addOns: [],
          variant: {},
        },
      }));
    }
  }, [activeProduct, dataGetProductById]);

  const createOrderMutation = useMutation({
    mutationFn: () => createOrder(orderForm),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ["getUserPoint"]});
      setLoadingOrder({
        open: false,
        message: "Your order has been placed successfully.",
      });
      setTimeout(() => {
        setLoadingOrder({open: false, message: ""});
        closeModalClickHandler();
      }, 2000);
    },
    onError: (error: Error) => {
      console.error("Error creating product:", error.message);
      setLoadingOrder({
        open: false,
        message: error.message,
      });
    },
  });

  const confirmOrderDetails = () => {
    setLoadingOrder({
      open: true,
      message: "",
    });
    createOrderMutation.mutate(undefined, {
      onSettled: () => {
        setTimeout(() => setLoadingOrder({open: false, message: ""}), 2000);
      },
    });
  };

  const onClaimNowSubmitHandler = () => {
  if (dataGetProductById?.product?.quantity <= 0) {
    return setLoadingOrder({
      open: false,
      message: `Sorry, the item is out of stock.`,
    });
  }

  if (data?.points?.coins < orderForm?.orderDetails?.price) {
    return setLoadingOrder({
      open: false,
      message: `You don't have enough coins.`,
    });
  }

  if (dataGetProductById?.product?.type === "Merch") {
    const requiredVariants = dataGetProductById?.product?.variant || [];

    if (requiredVariants.length > 0) {
      const missingVariants = requiredVariants
        .filter((v: Variant) => !selectedVariants[v.title])
        .map((v: Variant) => v.title);

      if (missingVariants.length > 0) {
        return setLoadingOrder({
          open: false,
          message: `Please select options for: ${missingVariants.join(", ")}`,
        });
      }
    }
    
    setAddressPage(true);
    return;
  }

  setLoadingOrder({
    open: true,
    message: "",
  });

  createOrderMutation.mutate(undefined, {
    onSettled: () => {
      setTimeout(() => setLoadingOrder({ open: false, message: "" }), 2000);
    },
  });
};

  const onVariantOptionClick = (variant: Option, variantTitle: string) => {
    setSelectedVariants((prev) => ({
      ...prev,
      [variantTitle]: variant,
    }));
    setOrderForm((previous: OrderFormState) => ({
      ...previous,
      orderDetails: {
        ...previous.orderDetails,
        variant: {
          ...(previous.orderDetails?.variant || {}),
          [variantTitle]: {id: variant.id, title: variant.title},
        },
      },
    }));
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    addOn: AddOnProps
  ) => {
    setSelectedAddOns((prevSelected) => {
      if (e.target.checked) {
        return [...prevSelected, addOn];
      } else {
        return prevSelected.filter(
          (item) => item.addonName !== addOn.addonName
        );
      }
    });
    setOrderForm((previous: OrderFormState) => ({
      ...previous,
      orderDetails: {
        ...previous.orderDetails,
        addOns: e.target.checked
          ? [...(previous.orderDetails?.addOns || []), addOn]
          : (previous.orderDetails?.addOns || []).filter(
              (item) => item.addonName !== addOn.addonName
            ),
      },
    }));
  };

  return (
    <div className={ProductDetailsModalStyles.modalDetailsProductContainer}>
      <div className={ProductDetailsModalStyles.modalMainContent}>
        {isLoadingGetProductById ? (
          <Spinner alignSpin="center-spinner" variant="medium-spinner" />
        ) : (
          <div className={ProductDetailsModalStyles.contentBlk}>
            {dataGetProductById?.product?.type === "Voucher" && (
              <>
                <div>
                  <div className={`${ProductDetailsModalStyles.flexBoxBlk}`}>
                    <div>
                      <h1>{dataGetProductById?.product?.productName}</h1>
                    </div>
                    <div>
                      <button
                        onClick={closeModalClickHandler}
                        className={`fa-solid fa-xmark
                ${ProductDetailsModalStyles.closeIconCreateBadge}`}></button>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className={ProductDetailsModalStyles.descriptionBlk}>
                  {isLoadingGetProductById ? (
                    <div className={ProductDetailsModalStyles.spinnerBlkDiv}>
                      <Spinner
                        variant="medium-spinner"
                        alignSpin="center-spinner"
                      />
                    </div>
                  ) : (
                    <>
                      {!imageLoaded && (
                        <div
                          className={ProductDetailsModalStyles.spinnerBlkDiv}>
                          <Spinner
                            variant="medium-spinner"
                            alignSpin="center-spinner"
                          />
                        </div>
                      )}
                      <img
                        src={dataGetProductById?.product?.imageURL}
                        alt={dataGetProductById?.product?.productName}
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                        style={{display: imageLoaded ? "block" : "none"}}
                      />

                      <div
                        className={ProductDetailsModalStyles.merchDetailsBlk}>
                        <div
                          className={
                            ProductDetailsModalStyles.headerFlexBoxBlk
                          }>
                          <div>
                            <h2>Amount</h2>
                          </div>
                          <div
                            className={ProductDetailsModalStyles.priceSection}>
                            <h1>PHP {dataGetProductById?.product?.price}</h1>
                          </div>
                        </div>
                        <hr
                          className={ProductDetailsModalStyles.horizontalLine}
                          style={{marginBottom: "20px", marginTop: "20px"}}
                        />
                        <p>{dataGetProductById?.product?.productDescription}</p>
                      </div>
                    </>
                  )}
                </div>

                <div className={ProductDetailsModalStyles.claimButtonSection}>
                  <button onClick={onClaimNowSubmitHandler}>
                    {loadingOrder.open ? (
                      <Spinner
                        variant="small-spinner"
                        alignSpin="center-spinner"
                      />
                    ) : (
                      <>
                        Claim Now <i className="fa-solid fa-arrow-right"></i>
                      </>
                    )}
                  </button>
                  {loadingOrder?.message && (
                    <p
                      className={
                        loadingOrder?.message ===
                        "Your order has been placed successfully."
                          ? ProductDetailsModalStyles.successMessage
                          : ProductDetailsModalStyles.errorMessage
                      }>
                      {loadingOrder?.message}
                    </p>
                  )}
                  <p>Once you claim this we cannot issue a refund</p>
                </div>
              </>
            )}

            {dataGetProductById?.product?.type === "Merch" &&
              (addressPage ? (
                <OrderDetailAddress
                  order={orderForm}
                  setOrderForm={setOrderForm}
                  setLoadingOrder={setLoadingOrder}
                  loadingOrder={loadingOrder}
                  confirmOrderDetails={confirmOrderDetails}
                  closeModalClickHandler={closeModalClickHandler}
                />
              ) : (
                <>
                  <div>
                    <div className={`${ProductDetailsModalStyles.flexBoxBlk}`}>
                      <div>
                        <h1>{dataGetProductById?.product?.productName}</h1>
                      </div>
                      <div>
                        <button
                          onClick={closeModalClickHandler}
                          className={`fa-solid fa-xmark
                ${ProductDetailsModalStyles.closeIconCreateBadge}`}>Close</button>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className={ProductDetailsModalStyles.descriptionBlk}>
                    {isLoadingGetProductById ? (
                      <div className={ProductDetailsModalStyles.spinnerBlkDiv}>
                        <Spinner
                          variant="medium-spinner"
                          alignSpin="center-spinner"
                        />
                      </div>
                    ) : (
                      <>
                        {!imageLoaded && (
                          <div
                            className={ProductDetailsModalStyles.spinnerBlkDiv}>
                            <Spinner
                              variant="medium-spinner"
                              alignSpin="center-spinner"
                            />
                          </div>
                        )}
                        <img
                          src={dataGetProductById?.product?.imageURL}
                          alt={dataGetProductById?.product?.productName}
                          onLoad={handleImageLoad}
                          onError={handleImageError}
                          style={{display: imageLoaded ? "block" : "none"}}
                        />

                        <div
                          className={ProductDetailsModalStyles.merchDetailsBlk}>
                          <div
                            className={
                              ProductDetailsModalStyles.headerFlexBoxBlk
                            }>
                            <div>
                              <h2>Amount</h2>
                            </div>
                            <div>
                              <h1>PHP {dataGetProductById?.product?.price}</h1>
                            </div>
                          </div>

                          {dataGetProductById?.product?.addOns?.length > 0 && (
                            <>
                              <hr
                                className={
                                  ProductDetailsModalStyles.horizontalLine
                                }
                                style={{marginBottom: "25px"}}
                              />
                              <div
                                className={
                                  ProductDetailsModalStyles.addOnsDetailsBlk
                                }>
                                <h2>Add ons</h2>
                                <div
                                  className={
                                    ProductDetailsModalStyles.addOnListBlk
                                  }>
                                  {dataGetProductById.product.addOns.map(
                                    (data: AddOnProps, index: number) => (
                                      <div
                                        className={
                                          ProductDetailsModalStyles.addOnCardList
                                        }
                                        key={index}>
                                        <div>
                                          <input
                                            type="checkbox"
                                            checked={selectedAddOns.some(
                                              (item: any) =>
                                                item.addonName ===
                                                data?.addonName
                                            )}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, data)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <h3>{data?.addonName}</h3>
                                        </div>
                                        <div>
                                          <h3>PHP {data?.price}</h3>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                          {dataGetProductById?.product?.variant && (
                            <div
                              className={
                                ProductDetailsModalStyles.variantSectionBlk
                              }>
                              {dataGetProductById?.product?.variant?.map(
                                (data: Variant, index: number) => {
                                  return (
                                    <div key={index}>
                                      <h1>{data.title}</h1>
                                      <div
                                        className={
                                          ProductDetailsModalStyles.options
                                        }>
                                        {data.options?.map(
                                          (option: Option, index: number) => {
                                            return (
                                              <button
                                                key={option.id}
                                                onClick={() =>
                                                  onVariantOptionClick(
                                                    option,
                                                    data.title
                                                  )
                                                }
                                                className={
                                                  selectedVariants[data.title]
                                                    ?.id === option.id
                                                    ? ProductDetailsModalStyles.selectedVariant
                                                    : ""
                                                }>
                                                {option.title}
                                              </button>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                          <hr
                            className={ProductDetailsModalStyles.horizontalLine}
                            style={{marginBottom: "25px", marginTop: "25px"}}
                          />
                          <p>
                            {dataGetProductById?.product?.productDescription}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={ProductDetailsModalStyles.claimButtonSection}>
                    <button onClick={onClaimNowSubmitHandler}>
                      {loadingOrder.open ? (
                        <Spinner
                          variant="small-spinner"
                          alignSpin="center-spinner"
                        />
                      ) : (
                        <>
                          Proceed to Check Out <i className="fa-solid fa-arrow-right"></i>
                        </>
                      )}
                    </button>
                    {loadingOrder?.message && (
                      <p
                        className={
                          loadingOrder?.message ===
                          "Your order has been placed successfully."
                            ? ProductDetailsModalStyles.successMessage
                            : ProductDetailsModalStyles.errorMessage
                        }>
                        {loadingOrder?.message}
                      </p>
                    )}
                  </div>
                </>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsModal;
