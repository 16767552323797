import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {OrderProps} from "../../types/ComponentProps";
import OrderStatusDropDownStyles from "./OrderStatusDropDown.module.css";
import editOrderStatus from "../../api/editOrderStatus";
import Spinner from "../spinner/Spinner";

const initialStatuses = [
  {
    label: "Purchased",
    className: OrderStatusDropDownStyles.statusPurchased,
    statusId: 0,
  },
  {
    label: "Pending",
    className: OrderStatusDropDownStyles.statusPending,
    statusId: 1,
  },
  {
    label: "Processed",
    className: OrderStatusDropDownStyles.statusProcessed,
    statusId: 2,
  },
];

type OrderStatusDropDownProps = {
  order: OrderProps;
};

const OrderStatusDropDown = ({order}: OrderStatusDropDownProps) => {
  const [selectedStatus, setSelectedStatus] = useState(
    initialStatuses.find((s) => s.statusId === order.orderStatus) ||
      initialStatuses[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState(
    initialStatuses.filter((s) => s.label !== selectedStatus.label)
  );
  const [isOpen, setIsOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: ({orderId, statusId}: {orderId: number; statusId: number}) =>
      editOrderStatus(orderId, {orderStatus: statusId}),
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: (error) => {
      console.error("Failed to update order status:", error);
    },
  });

  const handleStatusClick = (status: {
    label: string;
    className: string;
    statusId: number;
  }) => {
    setSelectedStatus(status);
    setAvailableStatuses(
      initialStatuses.filter((s) => s.label !== status.label)
    );
    setIsOpen(false);
    setIsLoading(true);
    mutation.mutate({
      orderId: Number(order.orderId),
      statusId: status.statusId,
    });
  };

  return (
    <div className={OrderStatusDropDownStyles.orderStatusContainer}>
      <div
        className={OrderStatusDropDownStyles.statusItem}
        onClick={() => setIsOpen(!isOpen)}>
        {isLoading ? (
          <Spinner variant="small-spinner" alignSpin="center-spinner" />
        ) : (
          <span className={selectedStatus.className}>
            {selectedStatus.label}
          </span>
        )}
        <span
          className={`${OrderStatusDropDownStyles.statusIcon} fa-solid fa-chevron-up`}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}></span>
      </div>

      {isOpen && availableStatuses.length > 0 && (
        <div className={OrderStatusDropDownStyles.dropdownMenu}>
          {availableStatuses.map((status, index) => (
            <div
              key={index}
              className={OrderStatusDropDownStyles.statusItem}
              onClick={() => handleStatusClick(status)}>
              <span className={status.className}>{status.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderStatusDropDown;
