import {useEffect, useState} from "react";
import ProductListStyles from "./ProductListStyles.module.css";
import Input from "../input/Input";

type VariantOption = {id: number; title: string};
type Variant = {id: number; title: string; options: VariantOption[]};

type MerchVariantProps = {
  setCreateProductForm: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >;
  createProductForm: any;
};

const MerchVariant = ({
  setCreateProductForm,
  createProductForm,
}: MerchVariantProps) => {
  const [variants, setVariants] = useState<Variant[]>(
    createProductForm.variant || []
  );

  // Sync with parent state whenever variants change
  useEffect(() => {
    setCreateProductForm((prev) => ({
      ...prev,
      variant: variants,
    }));
  }, [variants, setCreateProductForm]);

  const addVariant = () => {
    setVariants([
      ...variants,
      {id: Date.now(), title: "", options: [{id: Date.now(), title: ""}]},
    ]);
  };

  const addOption = (variantId: number) => {
    setVariants((prev) =>
      prev.map((variant) =>
        variant.id === variantId
          ? {
              ...variant,
              options: [...variant.options, {id: Date.now(), title: ""}],
            }
          : variant
      )
    );
  };

  const deleteVariant = (variantId: number) => {
    setVariants(variants.filter((variant) => variant.id !== variantId));
  };

  const deleteOption = (variantId: number, optionId: number) => {
    setVariants((prev) =>
      prev.map((variant) =>
        variant.id === variantId
          ? {
              ...variant,
              options: variant.options.filter(
                (option) => option.id !== optionId
              ),
            }
          : variant
      )
    );
  };

  const handleVariantChange = (variantId: number, newTitle: string) => {
    setVariants((prev) =>
      prev.map((variant) =>
        variant.id === variantId ? {...variant, title: newTitle} : variant
      )
    );
  };

  const handleOptionChange = (
    variantId: number,
    optionId: number,
    newTitle: string
  ) => {
    setVariants((prev) =>
      prev.map((variant) =>
        variant.id === variantId
          ? {
              ...variant,
              options: variant.options.map((option) =>
                option.id === optionId ? {...option, title: newTitle} : option
              ),
            }
          : variant
      )
    );
  };

  return (
    <div className={ProductListStyles.merchContainer}>
      {variants?.length >= 1 && <h1>Variants</h1>}

      {variants.map((variant, index) => (
        <div key={variant.id} className={ProductListStyles.merchBlock}>
          <button
            className={ProductListStyles.deleteVariantButton}
            onClick={() => deleteVariant(variant.id)}>
            <i className="fa-solid fa-xmark" />
          </button>
          <h3>Variant {index + 1}</h3>
          <Input
            type="text"
            label="Variant Title"
            onChangeHandler={(e) =>
              handleVariantChange(variant.id, e.target.value)
            }
            name="variantTitle"
            value={variant.title}
            maxLength={150}
          />
                    <div>
                      <p>Limited to 150 characters</p>
                    </div>
          <div className={ProductListStyles.containerOptionBlk}>
            {variant.options.map((option, index) => (
              <div className={ProductListStyles.optionBlockFlexBox} key={index}>
                <div>
                  <Input
                    type="text"
                    label="Option"
                    value={option.title}
                    onChangeHandler={(e) =>
                      handleOptionChange(variant.id, option.id, e.target.value)
                    }
                    maxLength={150}
                  />
                    <div>
                      <p>Limited to 150 characters</p>
                    </div>
                </div>
                <div>
                  <button
                    className={ProductListStyles.deleteOptionButton}
                    onClick={() => deleteOption(variant.id, option.id)}>
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
              </div>
            ))}
            <button
              className={ProductListStyles.addOptionButton}
              onClick={() => addOption(variant.id)}>
              + Add Option
            </button>
          </div>
        </div>
      ))}
      <button
        className={ProductListStyles.manageVariantButton}
        onClick={addVariant}>
        + Add Variant
      </button>
    </div>
  );
};

export default MerchVariant;
