import {useState, useEffect, useMemo} from "react";
import {useDropzone} from "react-dropzone";
import Input from "../input/Input";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import {CreateProductModalProps, AddOnProps} from "../../types/ComponentProps";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import {typeOptions, categoryOptions} from "./dropdown";
import CustomSelectAddOns from "../customSelectAddOns/CustomSelectAddOns";
import useGetProductById from "../../hooks/useGetProductById";
import CustomSelectProduct from "../customSelectProduct/CustomSelectProduct";
import ProductListStyles from "./ProductListStyles.module.css";
import useGetAllAddOns from "../../hooks/useGetAllAddOns";
import VoucherVariant from "./VoucherVariant";
import MerchVariant from "./MerchVariant";

const UpdateProductModal = ({
  onCloseClickHandler,
  inputChangeHandler,
  createProductForm,
  createProductIsLoading,
  errorHandler,
  setCreateProductForm,
  activeProductId,
  toggleState,
  updateProductionSubmitHandler,
  setToggleState,
}: CreateProductModalProps) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const {getAllAddOnsData} = useGetAllAddOns();
  const {isLoadingGetProductById, dataGetProductById, refetchGetProductById} =
    useGetProductById(Number(activeProductId));
  const handleImageLoad = () => setImageLoaded(true);
  const handleImageError = () => setImageLoaded(false);

  useEffect(() => {
    if (dataGetProductById?.product) {
      setCreateProductForm((prevForm: any) => ({
        ...prevForm,
        productName: dataGetProductById.product.productName || "",
        type: dataGetProductById.product.type || "",
        price: dataGetProductById.product.price || "",
        quantity: dataGetProductById.product.quantity || "",
        category: dataGetProductById.product.category || "",
        productDescription: dataGetProductById.product.productDescription || "",
        addOns: [...(dataGetProductById?.product?.addOns ?? [])],
        variant:
          Array.isArray(dataGetProductById.product.variant) &&
          dataGetProductById.product.variant.length
            ? dataGetProductById.product.variant
            : null,
      }));

      setToggleState(!!dataGetProductById.product.addOns?.length);
      setPreview(dataGetProductById.product.imageURL || null);
    }
  }, [dataGetProductById, setCreateProductForm, activeProductId]);

  const {getRootProps, getInputProps} = useDropzone({
    accept: {"image/jpeg": [], "image/png": []},
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setPreview(URL.createObjectURL(file));
        inputChangeHandler({target: {name: "productImage", value: file}});
      }
    },
  });

  const filterDataAddOns = useMemo(() => {
    return (
      getAllAddOnsData?.pages?.flatMap((page) =>
        page?.addons.filter(
          (addOn: AddOnProps) =>
            !createProductForm?.addOns?.some(
              (selectedAddOn) =>
                Number(selectedAddOn.addonId) === Number(addOn.addonId)
            )
        )
      ) ?? []
    );
  }, [getAllAddOnsData, createProductForm?.addOns]);

  const handleSelectAddOns = (selectedOption: AddOnProps) => {
    setCreateProductForm((prevForm: any) => {
      const addOnsArray = Array.isArray(prevForm.addOns) ? prevForm.addOns : [];
      const isDuplicate = addOnsArray.some(
        (addOn: AddOnProps) => addOn.addonId === selectedOption.addonId
      );
      if (isDuplicate) return prevForm;
      const updatedForm = {
        ...prevForm,
        addOns: [...addOnsArray, selectedOption],
      };
      return updatedForm;
    });
  };

  const handleSelectTypeAndCategory =
    (field: "type" | "category") =>
    (selectedOption: {value: string; label: string}) => {
      setCreateProductForm((prevForm: any) => ({
        ...prevForm,
        [field]: selectedOption.value,
      }));
    };

  const handleClose = () => {
    setPreview(null);
    onCloseClickHandler();
  };

  const removeListAddOns = (addonId?: number) => {
    setCreateProductForm((prevForm: any) => ({
      ...prevForm,
      addOns: addonId
        ? prevForm.addOns.filter(
            (addOn: AddOnProps) => Number(addOn.addonId) !== Number(addonId)
          )
        : null,
    }));
  };

  return (
    <div className={ProductListStyles.createProductModal}>
      <div className={ProductListStyles.createProductModalMainContent}>
        <div className={ProductListStyles.flexBoxBlk}>
          <div>
            <h1>Edit Product</h1>
          </div>
          <div>
            <button
              className={`fa-solid fa-xmark ${ProductListStyles.closeIconCreateBadge}`}
              onClick={handleClose}
              disabled={createProductIsLoading}></button>
          </div>
        </div>

        <hr className={ProductListStyles.horizontalLine} />

        {isLoadingGetProductById ? (
          <div style={{marginTop: "20px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : (
          <div className={ProductListStyles.scrollContainer}>
            {createProductForm?.type === "Voucher" && (
              <div>
                <div className={ProductListStyles.formSection}>
                  <h2 style={{marginTop: "20px"}}>Image</h2>
                  <div
                    {...getRootProps()}
                    className={ProductListStyles.dropzone}>
                    <input {...getInputProps()} />
                    {preview ? (
                      <div className={ProductListStyles.previewImageBlk}>
                        {!imageLoaded && (
                          <div className={ProductListStyles.spinnerBlkDiv}>
                            <Spinner
                              variant="medium-spinner"
                              alignSpin="center-spinner"
                            />
                          </div>
                        )}
                        <img
                          src={preview}
                          alt="Product Preview"
                          className={ProductListStyles.imagePreview}
                          onLoad={handleImageLoad}
                          onError={handleImageError}
                          style={{display: imageLoaded ? "block" : "none"}}
                        />
                      </div>
                    ) : (
                      <img
                        src={dataGetProductById?.product?.imageURL}
                        alt="Product Preview"
                        className={ProductListStyles.imagePreview}
                      />
                    )}
                  </div>
                  <h2>Basic Details</h2>

                  <div className={ProductListStyles.inputFlexBox}>
                    <div>
                      <Input
                        type="text"
                        label="Product Name"
                        onChangeHandler={inputChangeHandler}
                        name="productName"
                        value={createProductForm?.productName}
                      />
                    </div>
                    <div>
                      <Input
                        type="number"
                        label="Price"
                        onChangeHandler={inputChangeHandler}
                        name="price"
                        maxLength={6}
                        value={
                          createProductForm?.price
                            ? createProductForm.price.toString()
                            : ""
                        }
                      />
                    </div>
                  </div>

                  <div className={ProductListStyles.inputFlexBox}>
                    <div>
                      <Input
                        type="number"
                        label="Quantity"
                        maxLength={6}
                        onChangeHandler={inputChangeHandler}
                        name="quantity"
                        value={
                          createProductForm?.quantity !== null
                            ? createProductForm.quantity.toString()
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <div className={ProductListStyles.dropDownFlexContent}>
                        <label>Category</label>
                        <CustomSelectProduct
                          options={categoryOptions}
                          onSelect={handleSelectTypeAndCategory("category")}
                          placeholder="Select category"
                          value={
                            categoryOptions.find(
                              (option) =>
                                option.value === createProductForm?.category
                            ) || null
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <Input
                    type="text"
                    label="Description"
                    onChangeHandler={inputChangeHandler}
                    name="productDescription"
                    value={createProductForm?.productDescription || ""}
                  />
                </div>
              </div>
            )}

            {createProductForm?.type === "Merch" && (
              <div className={ProductListStyles.formSection}>
                <h2 style={{marginTop: "20px"}}>Image</h2>
                <div {...getRootProps()} className={ProductListStyles.dropzone}>
                  <input {...getInputProps()} />
                  {preview ? (
                    <div className={ProductListStyles.previewImageBlk}>
                      {!imageLoaded && (
                        <div className={ProductListStyles.spinnerBlkDiv}>
                          <Spinner
                            variant="medium-spinner"
                            alignSpin="center-spinner"
                          />
                        </div>
                      )}
                      <img
                        src={preview}
                        alt="Product Preview"
                        className={ProductListStyles.imagePreview}
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                        style={{display: imageLoaded ? "block" : "none"}}
                      />
                    </div>
                  ) : (
                    <img
                      src={dataGetProductById?.product?.imageURL}
                      alt="Product Preview"
                      className={ProductListStyles.imagePreview}
                    />
                  )}
                </div>
                <h2>Basic Details</h2>
                <div className={ProductListStyles.dropDownFlexContent}>
                  <label>Category</label>
                  <CustomSelectProduct
                    options={categoryOptions}
                    onSelect={handleSelectTypeAndCategory("category")}
                    placeholder="Select category"
                    value={
                      categoryOptions.find(
                        (option) => option.value === createProductForm?.category
                      ) || null
                    }
                  />
                </div>
                <Input
                  type="text"
                  label="Product Name"
                  onChangeHandler={inputChangeHandler}
                  name="productName"
                  value={createProductForm?.productName}
                />
                <div className={ProductListStyles.inputFlexBox}>
                  <div>
                    <Input
                      type="number"
                      label="Price"
                      onChangeHandler={inputChangeHandler}
                      name="price"
                      maxLength={6}
                      value={
                        createProductForm?.price
                          ? createProductForm.price.toString()
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <Input
                      type="number"
                      label="Quantity"
                      maxLength={6}
                      onChangeHandler={inputChangeHandler}
                      name="quantity"
                      value={
                        createProductForm?.quantity !== null
                          ? createProductForm.quantity.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className={ProductListStyles.addOnSection}>
                  <div className={ProductListStyles.flexBoxBlk}>
                    <div>
                      <h1>Add ons</h1>
                    </div>
                    <div>
                      <ToggleSwitch
                        toggleState={toggleState}
                        setToggleState={setToggleState}
                        addOns={createProductForm?.addOns}
                        disable={
                          createProductForm?.addOns.length > 0 ? true : false
                        }
                      />
                    </div>
                  </div>

                  {toggleState && (
                    <CustomSelectAddOns
                      options={filterDataAddOns}
                      onSelect={handleSelectAddOns}
                      setCreateProductForm={setCreateProductForm}
                      placeholder="Select Add-Ons"
                    />
                  )}

                  {createProductForm?.addOns.length === 0 ? null : (
                    <div className={ProductListStyles.addOnFlex}>
                      {createProductForm?.addOns.map(
                        (data: AddOnProps, index: number) => {
                          return (
                            <h1 key={index} className={ProductListStyles.addOn}>
                              <p>{data?.addonName} </p>
                              <p
                                onClick={() =>
                                  removeListAddOns(Number(data?.addonId))
                                }>
                                x
                              </p>
                            </h1>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
                <Input
                  type="text"
                  label="Description"
                  onChangeHandler={inputChangeHandler}
                  name="productDescription"
                  value={createProductForm?.productDescription || ""}
                />
                <hr
                  className={ProductListStyles.horizontalLine}
                  style={{marginTop: "30px", marginBottom: "20px"}}
                />{" "}
                <MerchVariant
                  createProductForm={createProductForm}
                  setCreateProductForm={setCreateProductForm}
                />
              </div>
            )}

            <hr
              className={ProductListStyles.horizontalLine}
              style={{marginTop: "30px", marginBottom: "20px"}}
            />
            {!errorHandler.success && errorHandler?.message ? (
              <Message variant="error">{errorHandler.message}</Message>
            ) : null}

            <button
              className={ProductListStyles.buttonCreateProduct}
              disabled={createProductIsLoading}
              onClick={updateProductionSubmitHandler}>
              {createProductIsLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                <>
                  Update Product<i className="fa-solid fa-arrow-right"></i>
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateProductModal;
