import {useEffect} from "react";
import Input from "../input/Input";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import {UpdateAddOnModalProps} from "../../types/ComponentProps";
import useGetAddOnById from "../../hooks/useGetAddOnById";
import AddOnsListStyles from "./AddOnsListStyles.module.css";

const UpdateAddOnModal = ({
  activeProductId,
  createAddOnIsLoading,
  createAddOnForm,
  inputChangeHandler,
  updateAddOnSubmitHandler,
  onCloseClickHandler,
  errorHandler,
  setCreateAddOnForm,
}: UpdateAddOnModalProps) => {
  const {isLoading, getAddOnByIdData} = useGetAddOnById(
    Number(activeProductId)
  );

  useEffect(() => {
    if (getAddOnByIdData?.addon) {
      setCreateAddOnForm((prevForm) => ({
        ...prevForm,
        addonName: getAddOnByIdData.addon.addonName || "",
        addonDescription: getAddOnByIdData.addon.addonDescription || "",
        price: getAddOnByIdData.addon.price || 0,
      }));
    }
  }, [getAddOnByIdData, setCreateAddOnForm, activeProductId]);

  return (
    <div className={AddOnsListStyles.addOnModal}>
      <div className={AddOnsListStyles.createAddOnModalMainContent}>
        <div className={AddOnsListStyles.flexBoxBlk}>
          <div>
            <h1>Edit Add-On</h1>
          </div>
          <div>
            <button
              className={`fa-solid fa-xmark ${AddOnsListStyles.closeIconCreateBadge}`}
              onClick={onCloseClickHandler}
              disabled={createAddOnIsLoading}>Close</button>
          </div>
        </div>
        <hr className={AddOnsListStyles.horizontalLine} />

        {isLoading ? (
          <div style={{marginTop: "20px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : (
          <div className={AddOnsListStyles.scrollContainer}>
            <div className={AddOnsListStyles.formSection}>
              <Input
                type="text"
                label="Add-On Name"
                onChangeHandler={inputChangeHandler}
                name="addonName"
                value={createAddOnForm?.addonName}
              />
              <Input
                type="text"
                label="Add-On Description"
                onChangeHandler={inputChangeHandler}
                name="addonDescription"
                value={createAddOnForm?.addonDescription}
              />
              <Input
                type="number"
                label="Price"
                onChangeHandler={inputChangeHandler}
                name="price"
                value={
                  createAddOnForm?.price ? createAddOnForm.price.toString() : ""
                }
              />
              <hr
                className={AddOnsListStyles.horizontalLine}
                style={{marginTop: "30px", marginBottom: "20px"}}
              />
              {!errorHandler.success && errorHandler?.message ? (
                <Message variant="error">{errorHandler.message}</Message>
              ) : null}
              <button
                className={AddOnsListStyles.buttoncreateAddOn}
                onClick={updateAddOnSubmitHandler}
                disabled={createAddOnIsLoading}>
                {createAddOnIsLoading ? (
                  <Spinner variant="small-spinner" alignSpin="center-spinner" />
                ) : (
                  <>
                    Update Add-On<i className="fa-solid fa-arrow-right"></i>
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateAddOnModal;
