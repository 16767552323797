import {useInfiniteQuery} from "@tanstack/react-query";
import getAllProducts from "../api/getAllProducts";

const useGetAllProducts = () => {
  const {
    data: getAllProductsData,
    isLoading: isLoadingGetAllProducts,
    isError: isErrorGetAllProducts,
    refetch: refetchGetAllProductsData,
    fetchNextPage: fetchNextPageGetAllProducts,
    hasNextPage: hasNextPageGetAllProducts,
    isFetchingNextPage: isFetchingNextPageGetAllProducts,
    isFetching: isFetchinGetAllProducts,
  } = useInfiniteQuery({
    queryKey: ["getAllProducts"],
    queryFn: getAllProducts,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });
  return {
    isLoadingGetAllProducts,
    getAllProductsData,
    isErrorGetAllProducts,
    refetchGetAllProductsData,
    fetchNextPageGetAllProducts,
    hasNextPageGetAllProducts,
    isFetchingNextPageGetAllProducts,
    isFetchinGetAllProducts,
  };
};

export default useGetAllProducts;
