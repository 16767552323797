import {useInfiniteQuery} from "@tanstack/react-query";
import getAllAddOns from "../api/getAllAddOns";

const useGetAllAddOns = () => {
  const {
    data: getAllAddOnsData,
    isLoading: isLoadingGetAllAddOns,
    isError: isErrorGetAllAddOns,
    refetch: refetchGetAllAddOnsData,
    fetchNextPage: fetchNextPageGetAllAddOns,
    hasNextPage: hasNextPageGetAllAddOns,
    isFetchingNextPage: isFetchingNextPageGetAllAddOns,
    isFetching: isFetchinGetAllAddOns,
  } = useInfiniteQuery({
    queryKey: ["getAllAddOns"],
    queryFn: getAllAddOns,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage <= lastPage.totalPages
        ? lastPage.nextPage
        : undefined;
    },
    refetchOnWindowFocus: false,
  });
  return {
    getAllAddOnsData,
    isLoadingGetAllAddOns,
    isErrorGetAllAddOns,
    refetchGetAllAddOnsData,
    fetchNextPageGetAllAddOns,
    hasNextPageGetAllAddOns,
    isFetchingNextPageGetAllAddOns,
    isFetchinGetAllAddOns,
  };
};

export default useGetAllAddOns;
