import axiosInstance from "./axiosInstance";
import {OrderProps} from "../types/ComponentProps";

const createOrder = async (formData: OrderProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/createOrder", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error: unknown) {
    let errorMessage = "Error Order Process. Try again.";
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    if (typeof error === "object" && error !== null) {
      const err = error as {response?: {data?: {message?: string}}};
      errorMessage = err.response?.data?.message || errorMessage;
    }

    throw new Error(errorMessage);
  }
};

export default createOrder;
