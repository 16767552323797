import BadgesList from "../../components/badgesList/BadgesList";
import NavLinksAdmin from "../../components/navlinksAdmin/NavLinksAdmin";

const AdminCreateBadge = () => {
  return (
    <div className="inside-page-container-blk">
      <div className="create-badge-main-container">
        <NavLinksAdmin />
        <div>
          <BadgesList />
        </div>
      </div>
    </div>
  );
};

export default AdminCreateBadge;
