import Input from "../input/Input";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import {CreateAddOnModalProps} from "../../types/ComponentProps";
import AddOnsListStyles from "./AddOnsListStyles.module.css";

const CreateAddOnModal = ({
  createAddOnSubmitHandler,
  createAddOnIsLoading,
  createAddOnForm,
  inputChangeHandler,
  onCloseClickHandler,
  errorHandler,
}: CreateAddOnModalProps) => {
  return (
    <div className={AddOnsListStyles.addOnModal}>
      <div className={AddOnsListStyles.createAddOnModalMainContent}>
        <div className={AddOnsListStyles.flexBoxBlk}>
          <div>
            <h1>Add New Add-On</h1>
          </div>
          <div>
            <button
              className={`fa-solid fa-xmark ${AddOnsListStyles.closeIconCreateBadge}`}
              onClick={onCloseClickHandler}
              disabled={createAddOnIsLoading}>Close</button>
          </div>
        </div>
        <hr className={AddOnsListStyles.horizontalLine} />
        <div className={AddOnsListStyles.scrollContainer}>
          <div className={AddOnsListStyles.formSection}>
            <Input
              type="text"
              label="Add-On Name"
              onChangeHandler={inputChangeHandler}
              name="addonName"
              value={createAddOnForm?.addonName}
            />
            <Input
              type="text"
              label="Add-On Description"
              onChangeHandler={inputChangeHandler}
              name="addonDescription"
              value={createAddOnForm?.addonDescription}
            />
            <Input
              type="number"
              label="Price"
              onChangeHandler={inputChangeHandler}
              name="price"
              value={
                createAddOnForm?.price ? createAddOnForm.price.toString() : ""
              }
            />
            <hr
              className={AddOnsListStyles.horizontalLine}
              style={{marginTop: "30px", marginBottom: "20px"}}
            />
            {!errorHandler.success && errorHandler?.message ? (
              <Message variant="error">{errorHandler.message}</Message>
            ) : null}
            <button
              className={AddOnsListStyles.buttoncreateAddOn}
              onClick={createAddOnSubmitHandler}
              disabled={createAddOnIsLoading}>
              {createAddOnIsLoading ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                <>
                  Add New Add-On<i className="fa-solid fa-arrow-right"></i>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAddOnModal;
