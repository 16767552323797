import axiosInstance from "./axiosInstance";

const getAllAddOns = async ({pageParam = 1}) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const {data} = await axiosInstance.get(
      `/api/getAllAddons?page=${pageParam}&limit=8`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return {
      addons: data.addons,
      nextPage: pageParam + 1,
      totalPages: data.pagination.totalPages,
    };
  } catch (error) {
    throw new Error("Error fetching user points.");
  }
};

export default getAllAddOns;
