import {AddOnProps} from "../../types/ComponentProps";
import ToggleSwitchStyles from "./ToggleSwitch.module.css";

type ToggleProps = {
  setToggleState: (prev: (prevState: boolean) => boolean) => void;
  toggleState: boolean;
  addOns?: any;
  disable?: boolean;
};

const ToggleSwitch: React.FC<ToggleProps> = ({
  toggleState,
  setToggleState,
  addOns,
  disable = false,
}) => {
  const isDisabled = disable;

  const handleToggle = () => {
    if (isDisabled) return;
    setToggleState((previous) => !previous);
  };

  return (
    <div
      className={`${ToggleSwitchStyles.toggleContainer} ${
        toggleState ? ToggleSwitchStyles.on : ToggleSwitchStyles.off
      } ${isDisabled ? ToggleSwitchStyles.disabled : ""}`}
      onClick={handleToggle}
      style={{cursor: isDisabled ? "not-allowed" : "pointer"}}>
      <div className={ToggleSwitchStyles.toggleCircle} />
    </div>
  );
};

export default ToggleSwitch;
