import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
// Public Pages
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";

// Private Pages
import PrivateRoute from "./utils/PrivateRoute";
import AdminRoute from "./utils/AdminRoute";
import AdminCreateUser from "./pages/private/AdminCreateUser";
import AdminCreateHashTag from "./pages/private/AdminCreateHashTag";
import AdminCreateBadge from "./pages/private/AdminCreateBadge";
import DashboardPage from "./pages/private/DashboardPage";
import RewardsPage from "./pages/private/RewardsPage";
import CareersPage from "./pages/private/CareersPage";
import LeaderboardPage from "./pages/private/LeaderboardPage";
import TeamPage from "./pages/private/TeamPage";
import ProfilePage from "./pages/private/ProfilePage";
import EditProfile from "./pages/private/EditProfile";
import PublicProfilePage from "./pages/private/PublicProfilePage";
import ShopPage from "./pages/private/ShopPage";
import AdminCreateProduct from "./pages/private/AdminCreateProduct";
import AdminCreateAddOn from "./pages/private/AdminCreateAddOn";
import AdminOrderList from "./pages/private/AdminOrderList";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/rewards" element={<RewardsPage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/profile/:userId" element={<PublicProfilePage />} />
          <Route path="/shop" element={<ShopPage />} />
        </Route>
        <Route path="/" element={<AdminRoute />}>
          <Route path="/create-user" element={<AdminCreateUser />} />
          <Route path="/create-badge" element={<AdminCreateBadge />} />
          <Route path="/create-hashTag" element={<AdminCreateHashTag />} />
          <Route path="/create-product" element={<AdminCreateProduct />} />
          <Route path="/create-addons" element={<AdminCreateAddOn />} />
          <Route path="/orders" element={<AdminOrderList />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
