import useGetAllPostLikes from "../../hooks/useGetAllPostLikes";
import {LikesModalProps, LikesDataProps} from "../../types/ComponentProps";
import Spinner from "../spinner/Spinner";
import LikesModalStyles from "./LikesModalStyles.module.css";
import AvatarLikesModal from "./AvatarLikesModal";

import LikeReaction from "../../assets/img/reactions/like.svg";
import LoveReaction from "../../assets/img/reactions/love.svg";
import CareReaction from "../../assets/img/reactions/care.svg";
import LaughReaction from "../../assets/img/reactions/laugh.svg";
import ApplauseReaction from "../../assets/img/reactions/applause.svg";

import SanaOlReaction from "../../assets/img/reactions/sanaol.svg";
import LodiReaction from "../../assets/img/reactions/lodi.svg";

const reactionIcons: Record<string, string> = {
  Like: LikeReaction,
  Love: LoveReaction,
  Sanaol: SanaOlReaction,
  Laugh: LaughReaction,
  Lodi: LodiReaction,
};

const LikesModal = ({post, setSelectedCardModal}: LikesModalProps) => {
  const {likes, isLoading} = useGetAllPostLikes(Number(post?.postId));

  const onCloseClick = () => setSelectedCardModal?.(false);

  const userReaction = (email: string) =>
    post?.likes?.find((data) => data?.email === email)?.reaction ?? "Like";

  const shouldScroll = (post?.likes?.length ?? 0) > 5;

  return (
    <div className={LikesModalStyles.likesModalContainer}>
      <div className={LikesModalStyles.likesModalMain}>
        <i className="fa-solid fa-xmark" onClick={onCloseClick}></i>
        <h1>Reactions</h1>
        <div
          className={LikesModalStyles.container}
          style={
            shouldScroll ? {overflowX: "scroll", maxHeight: "250px"} : undefined
          }>
          {isLoading ? (
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          ) : (
            likes?.map((data: LikesDataProps, index: number) => {
              const reaction = userReaction(data?.email);
              return (
                <div key={index} className={LikesModalStyles.flexBoxBlk}>
                  <div className={LikesModalStyles.avatarSectionBlk}>
                    <div className={LikesModalStyles.reactionSectionBlk}>
                      {reactionIcons[reaction] && (
                        <img src={reactionIcons[reaction]} alt={reaction} />
                      )}
                    </div>
                    <AvatarLikesModal userId={data?.id} />
                  </div>
                  <div>
                    <div className={LikesModalStyles.flexBoxBlkInside}>
                      <h3>
                        {data?.firstName} {data?.lastName}
                      </h3>
                      <span>{data?.user_profile?.pronouns}</span>
                    </div>
                    <p>{data?.email}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default LikesModal;
