import React, {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import {jwtDecode} from "jwt-decode";
import {
  CreatePostType,
  PostCardDetailsProps,
  RecipientListProps,
  TokenPayload,
  SelectedHashTagField,
  LeaderboardUpdatedProps,
} from "../../types/ComponentProps";
import createPost from "../../api/createPost";
import Block from "../../components/blocks/Block";
import Allowance from "../../components/summary/Allowance";
import GiveablePoints from "../../components/summary/GiveablePoints";
import RedeemablePoints from "../../components/summary/RedeemablePoints";
import Coins from "../../components/summary/Coins";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import SpecialRecognition from "../../components/specialRecognition/SpecialRecognition";
import LevelBlock from "../../components/level/LevelBlock";
import LeaderboardList from "../../components/leaderboard/LeaderboardList";
import PostBlock from "../../components/postBlock/PostBlock";
import PostCard from "../../components/postCard/PostCard";
import useGetUserPoint from "../../hooks/useGetUserPoint";
import useGetLeaderBoard from "../../hooks/useGetLeaderBoard";
import useGetAllNotification from "../../hooks/useGetAllNotification";
import useGetAllPosts from "../../hooks/useGetAllPosts";
import Spinner from "../../components/spinner/Spinner";
import HashTagBlock from "../../components/hashTagBlock/HashTagBlock";
import BadgeSelectionModal from "../../components/badgeSelectionModal/BadgeSelectionModal";
import AdminPostCard from "../../components/postCard/AdminPostCard";
import Navigation from "../../components/navigation/Navigation";
import LikesModal from "../../components/likesModal/LikesModal";
import EditPostModal from "../../components/editPostModal/EditPostModal";
import {useNotification} from "../../context/NotificationContext";

const DashboardPage = () => {
  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();

  const userDetails: TokenPayload = jwtDecode(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const {data, refetchGetUserPoint} = useGetUserPoint();
  const {leaderBoardList, isLoadingLeaderBoardList, refetchLeaderBoardList} =
    useGetLeaderBoard();
  const {refetch: refetchNotification} = useGetAllNotification();

  const {ref, inView} = useInView();
  const {
    isLoading,
    data: allPosts,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useGetAllPosts();
  const queryClient = useQueryClient();

  const formInitialState: CreatePostType = {
    message: "",
    imagePost: null,
    pointsGiven: 0,
    recipients: null,
    hashTagIds: [],
  };

  // States
  const [formPost, setFormPost] = useState(formInitialState);
  const [imageValidation, setImageValidation] = useState<string | null>(null);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [postListLoading, setPostListLoading] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<RecipientListProps[]>([]);
  const [emptyValidation, setEmptyValidation] = useState<boolean>(false);
  const [emptyPoints, setEmptyPoints] = useState<boolean>(false);
  const [exceedPointsValidation, setExceedPointsValidation] =
    useState<boolean>(false);
  const [selectedHashTag, setSelectedHashTag] = useState<SelectedHashTagField>(
    []
  );
  const [modalRecognition, setModalRecognition] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [selectedCardModal, setSelectedCardModal] = useState<boolean>(false);

  const [editPostModal, setEditPostModal] = useState<boolean>(false);
  const [editCardSelection, setEditCardSelection] = useState<number | null>(
    null
  );

  const sendNotificationOnCreatePost = () => {
    socketState?.emit("sendMentionNotification", {
      senderDetails: userDetails,
      receiverDetails: formPost,
    });
  };

  // Mutations
  const createPostMutation = useMutation({
    mutationFn: createPost,
    onSuccess: async () => {
      try {
        sendNotificationOnCreatePost();
        setPostLoading(false);
        setFilteredUsers([]);
        setFormPost(formInitialState);
        setPostListLoading(true);
        await Promise.all([
          queryClient.invalidateQueries({queryKey: ["getAllPosts"]}),
          queryClient.invalidateQueries({queryKey: ["getLeaderBoardList"]}),
          queryClient.invalidateQueries({queryKey: ["getUserPoint"]}),
          queryClient.invalidateQueries({queryKey: ["getNotificationById"]}),
          queryClient.invalidateQueries({queryKey: ["getAllHashTag"]}),
          queryClient.invalidateQueries({
            queryKey: ["getAllHashTagListDashboard"],
          }),
        ]);
        await Promise.all([
          refetch(),
          refetchLeaderBoardList(),
          refetchGetUserPoint(),
          refetchNotification(),
        ]);
      } catch (error) {
        console.error("Error invalidating queries:", error);
      } finally {
        setPostListLoading(false);
        setImageValidation(null);
      }
    },
    onError: (error: Error) => {
      setPostLoading(false);
      setPostListLoading(false);
      setImageValidation(null);
      console.error("Error creating user:", error);
    },
  });

  // UseEffect
  useEffect(() => {
    if (formPost?.recipients?.length === 0) {
      setFormPost((previous) => ({
        ...previous,
        recipients: null,
      }));
    }

    if (inView && hasNextPage) {
      fetchNextPage();
    }
    if (formPost?.message) {
      setEmptyValidation(false);
    }
  }, [
    inView,
    fetchNextPage,
    hasNextPage,
    formPost?.message,
    formPost?.recipients,
  ]);

  useEffect(() => {
    setFormPost((previous) => ({
      ...previous,
      hashTagIds: selectedHashTag,
    }));
  }, [selectedHashTag]);

  useEffect(() => {
    if (userDetails) {
      window.history.pushState(null, document.title, window.location.href);
      const handlePopState = () => {
        window.history.pushState(null, document.title, window.location.href);
      };
      window.addEventListener("popstate", handlePopState);
      return () => window.removeEventListener("popstate", handlePopState);
    }
  }, [userDetails]);

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const {value, name} = e.target;
    setFormPost((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const onPostClickHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formPost?.message && !formPost?.imagePost) {
      return setEmptyValidation(true);
    }
    if (formPost?.pointsGiven && !formPost?.recipients) {
      return setEmptyPoints(true);
    }

    setPostLoading(true);
    try {
      setEmptyPoints(false);
      setExceedPointsValidation(false);
      setImageValidation(null);
      await createPostMutation.mutateAsync(formPost);
      setFormPost(formInitialState);
      setSelectedHashTag([]);
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;
      if (errorMessage === "You cannot give away more points than available") {
        setExceedPointsValidation(true);
      }
      console.log(error);
    } finally {
      setPostLoading(false);
    }
  };

  const leaderboardUsers =
    leaderBoardList?.pages.flatMap(
      (user: LeaderboardUpdatedProps) => user.user
    ) || [];

  if (!socketState) {
    return (
      <>
        <Navigation />
        <div className="inside-page-container-blk">
          <div
            className="main-container-blk"
            style={{
              position: "relative",
              height: "100vh",
              paddingTop: "200px",
            }}>
            <Spinner alignSpin="center-spinner" variant="large-spinner" />
          </div>
        </div>
      </>
    );
  }

  return (
    <WithNavTemplate
      socket={!socketState ? null : socketState}
      setNotificationModal={setNotificationModal}
      setSelectedPostNotification={setSelectedPostNotification}
      setSelectedNotificationId={setSelectedNotificationId}>
      {modalRecognition ? (
        <BadgeSelectionModal
          setModalRecognition={setModalRecognition}
          socket={socketState}
        />
      ) : null}

      {selectedCardModal ? (
        <LikesModal
          post={selectedCard}
          setSelectedCardModal={setSelectedCardModal}
        />
      ) : null}

      {editPostModal ? (
        <EditPostModal
          setEditPostModal={setEditPostModal}
          editCardSelection={editCardSelection}
          setEditCardSelection={setEditCardSelection}
        />
      ) : null}

      <div className="inside-page-container-blk">
        <div className="main-container-blk user-dashboard-main-section">
          <div className="left-section-dashboard">
            <Block title="Summary" navigation="dots">
              <Allowance />
              <GiveablePoints data={data} />
              <RedeemablePoints data={data} />
              <Coins data={data} />
            </Block>
            {userDetails?.isAdmin ? (
              <SpecialRecognition setModalRecognition={setModalRecognition} />
            ) : (
              <LevelBlock data={data} />
            )}

            <Block
              title="Leaderboard"
              navigation="viewall"
              linkURL="leaderboard">
              <LeaderboardList
                leaderBoardListLoading={isLoadingLeaderBoardList}
                leaderBoardList={leaderboardUsers}
              />
            </Block>
          </div>
          <div className="center-section-dashboard">
            <PostBlock
              formPost={formPost}
              setFormPost={setFormPost}
              data={data}
              postLoading={postLoading}
              onSubmitHandler={onPostClickHandler}
              onChangeHandler={onChangeHandler}
              filteredUsers={filteredUsers}
              setFilteredUsers={setFilteredUsers}
              emptyValidation={emptyValidation}
              emptyPoints={emptyPoints}
              setEmptyPoints={setEmptyPoints}
              setEmptyValidation={setEmptyValidation}
              exceedPointsValidation={exceedPointsValidation}
              setExceedPointsValidation={setExceedPointsValidation}
              selectedHashTag={selectedHashTag}
              setSelectedHashTag={setSelectedHashTag}
              imageValidation={imageValidation}
              setImageValidation={setImageValidation}
            />

            <hr />
            {isLoading || postListLoading ? (
              <Spinner alignSpin="center-spinner" variant="medium-spinner" />
            ) : allPosts?.pages[0]?.posts.length === 0 ? (
              <p className="not-available-blk">No posts available</p>
            ) : (
              allPosts?.pages.map((page, index) => (
                <React.Fragment key={index}>
                  {page.posts.map((post: PostCardDetailsProps, index: number) =>
                    post?.badgeId ? (
                      <AdminPostCard
                        key={index}
                        post={post}
                        socket={socketState}
                        setSelectedCardModal={setSelectedCardModal}
                        setSelectedCard={setSelectedCard}
                      />
                    ) : (
                      <PostCard
                        key={index}
                        post={post}
                        socket={socketState}
                        setSelectedCardModal={setSelectedCardModal}
                        setSelectedCard={setSelectedCard}
                        setEditPostModal={setEditPostModal}
                        setEditCardSelection={setEditCardSelection}
                      />
                    )
                  )}
                </React.Fragment>
              ))
            )}
            <div ref={ref}>
              {isFetchingNextPage ? (
                <Spinner alignSpin="center-spinner" variant="medium-spinner" />
              ) : hasNextPage ? null : isLoading ||
                postListLoading ? null : allPosts?.pages[0]?.posts.length ===
                0 ? null : (
                <div className="all-posts-label-blk">
                  <div></div>
                  <p>You have seen all the posts</p>
                  <div></div>
                </div>
              )}
            </div>
          </div>

          <div className="right-section-dashboard">
            <Block title="Trending">
              <HashTagBlock />
            </Block>
          </div>
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default DashboardPage;
