import {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import useGetAllProducts from "../../hooks/useGetAllProducts";
import useGetUserPoint from "../../hooks/useGetUserPoint";
import {ProductProps} from "../../types/ComponentProps";
import Spinner from "../../components/spinner/Spinner";
import WalletIcon from "../../assets/img/navicons/wall-icon-2.png";
import ProductCard from "../../components/productCard/ProductCard";

import ProductListContainerStyles from "./ProductListContainer.module.css";

interface ProductListContainerProps {
  showModalClickHandler: (productId: number) => void;
}

const ProductListContainer: React.FC<ProductListContainerProps> = ({
  showModalClickHandler,
}) => {
  const {
    data: userPoints,
    error: userPointsError,
    isLoading,
  } = useGetUserPoint();
  const {
    getAllProductsData,
    isLoadingGetAllProducts,
    isErrorGetAllProducts,
    fetchNextPageGetAllProducts,
    hasNextPageGetAllProducts,
    isFetchingNextPageGetAllProducts,
  } = useGetAllProducts();
  const {ref, inView} = useInView();

  const productList =
    getAllProductsData?.pages.flatMap((page: any) => page.products) || [];

  useEffect(() => {
    if (inView && hasNextPageGetAllProducts) {
      fetchNextPageGetAllProducts();
    }
  }, [inView, fetchNextPageGetAllProducts, hasNextPageGetAllProducts]);

  return (
    <div className="main-container inside-page-container-blk">
      <div className={ProductListContainerStyles.shopPageInnerBlk}>
        {/* Left Section - User Coins */}
        <div className={ProductListContainerStyles.shopPageLeftSectionBlk}>
          <div className={ProductListContainerStyles.shopPageCoinsSectionBlk}>
            <h2>Total Coins in Wallet</h2>
            <div className={ProductListContainerStyles.flexBoxBlk}>
              <img src={WalletIcon} alt="Coins" />
              <div>
                {userPointsError ? (
                  <p className="error-text">Failed to load points</p>
                ) : (
                  <>
                    <h1>
                      {isLoading ? (
                        <Spinner
                          variant="medium-spinner"
                          alignSpin="center-spinner"
                        />
                      ) : (
                        userPoints?.points?.coins ?? 0
                      )}
                    </h1>
                    <span>Coins</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Section - Products */}
        <div className={ProductListContainerStyles.shopPageRightSectionBlk}>
          <h1>Shop All</h1>
          <div
            className={ProductListContainerStyles.containerProductListShopBlk}>
            {isLoadingGetAllProducts ? (
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            ) : isErrorGetAllProducts ? (
              <p className="error-text">Failed to load products</p>
            ) : (
              <div
                className={ProductListContainerStyles.containerBlkProductList}>
                {productList.length > 0 ? (
                  productList?.map((product: ProductProps) => (
                    <ProductCard
                      key={product?.productId}
                      product={product}
                      showModalClickHandler={showModalClickHandler}
                    />
                  ))
                ) : (
                  <p>No Products Available.</p>
                )}
              </div>
            )}

            <div ref={ref} style={{height: "1px"}}></div>
            {isFetchingNextPageGetAllProducts && (
              <div style={{display: "block", padding: "20px 0px"}}>
                <Spinner variant="medium-spinner" alignSpin="center-spinner" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListContainer;
