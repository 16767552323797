import AddOnsList from "../../components/addOns/AddOnsList";
import NavLinksAdmin from "../../components/navlinksAdmin/NavLinksAdmin";

const AdminCreateAddOn = () => {
  return (
    <div className="inside-page-container-blk">
      <div className="admin-page-main-container">
        <NavLinksAdmin />
        <div>
          <AddOnsList />
        </div>
      </div>
    </div>
  );
};

export default AdminCreateAddOn;
