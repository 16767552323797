import React, {ChangeEvent, useEffect, useState} from "react";
import {OrderProps} from "../../types/ComponentProps";
import Input from "../input/Input";
import CustomSelect from "../customSelect/CustomSelect";
import ProductDetailsModalStyles from "./ProductDetailsModalStyles.module.css";
import {provinceOptions} from "../editProfile/dropdown";
import Spinner from "../spinner/Spinner";

type OrderDetailAddressProps = {
  order: OrderProps;
  setOrderForm: React.Dispatch<React.SetStateAction<OrderProps>>;
  confirmOrderDetails: () => void;
  setLoadingOrder: React.Dispatch<
    React.SetStateAction<{message: string; open: boolean}>
  >;
  loadingOrder: {
    message: string;
    open: boolean;
  };
  closeModalClickHandler: () => void;
};

const OrderDetailAddress = ({
  order,
  setOrderForm,
  loadingOrder,
  setLoadingOrder,
  confirmOrderDetails,
  closeModalClickHandler,
}: OrderDetailAddressProps) => {
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (loadingOrder?.message) {
      setLoadingOrder((prev) => ({...prev, message: ""}));
    }
  }, []);

  const onChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    if (errors) {
      setErrors(false);
    }
    setOrderForm((prvious) => ({
      ...prvious,
      [name]: value,
    }));
  };

  const handleSelect =
    (field: "province") => (selectedOption: {value: string; label: string}) => {
      setOrderForm((prvious) => ({
        ...prvious,
        [field]: selectedOption.value,
      }));
    };

  const handleSubmit = () => {
    if (
      !order?.mobilePhone ||
      !order?.city ||
      !order?.province ||
      !order?.postalCode ||
      !order?.landmarks
    ) {
      return setErrors(true);
    }
    if (errors) {
      setErrors(false);
    }
    confirmOrderDetails();
  };

  return (
    <div className={ProductDetailsModalStyles.orderDetailsAddressSection}>
      <div className={`${ProductDetailsModalStyles.flexBoxBlk}`}>
        <div>
          <h1>Confirm Delivery Address</h1>
        </div>
        <div>
          <button
            onClick={closeModalClickHandler}
            className={`fa-solid fa-xmark
                ${ProductDetailsModalStyles.closeIconCreateBadge}`}></button>
        </div>
      </div>
      <hr />
      <div className={ProductDetailsModalStyles.productFormSection}>
        <div>
          <Input
            type="text"
            name="fullName"
            value={order?.fullName}
            onChangeHandler={onChangeHandler}
            label="Full Name"
            disabled={true}
          />
          <div className={ProductDetailsModalStyles.flexBoxBlk}>
            <div>
              <Input
                type="text"
                value={order?.email}
                name="email"
                onChangeHandler={onChangeHandler}
                label="Email"
                disabled={true}
              />
            </div>
            <div>
              <Input
                type="number"
                value={order?.mobilePhone}
                name="mobilePhone"
                onChangeHandler={onChangeHandler}
                label="Mobile Number"
              />
            </div>
          </div>
          <div
            style={{marginBottom: "20px"}}
            className={ProductDetailsModalStyles.flexBoxBlk}>
            <div>
              <Input
                type="text"
                value={order?.city}
                name="city"
                onChangeHandler={onChangeHandler}
                label="City"
              />
            </div>
            <div>
              <label>Province</label>
              <CustomSelect
                options={provinceOptions}
                onSelect={handleSelect("province")}
                placeholder={"Select Province"}
              />
            </div>
            <div>
              <Input
                type="number"
                value={order?.postalCode}
                name="postalCode"
                onChangeHandler={onChangeHandler}
                label="Postal Code"
              />
            </div>
          </div>
          <div>
            <label>Landmarks</label>
            <textarea
              spellCheck="false"
              maxLength={160}
              name="landmarks"
              value={order?.landmarks}
              onChange={onChangeHandler}></textarea>
            <div className={ProductDetailsModalStyles.footnote}>
              <p>Limited to 160 characters</p>
            </div>
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              margin: "10px",
              justifyContent: "flex-end",
            }}>
            {errors ? (
              <p className={ProductDetailsModalStyles.errorText}>
                All fields are required
              </p>
            ) : (
              loadingOrder.message && <p>{loadingOrder.message}</p>
            )}
          </div>
          <div className={ProductDetailsModalStyles.orderButtonSectionConfirm}>
            <button
              onClick={handleSubmit}
              className={ProductDetailsModalStyles.orderDetailsConfirmButton}>
              {loadingOrder.open ? (
                <Spinner variant="small-spinner" alignSpin="center-spinner" />
              ) : (
                <>
                  Claim Now <i className="fa-solid fa-arrow-right"></i>
                </>
              )}
            </button>
          </div>
                              <p>Once you claim this we cannot issue a refund</p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailAddress;
