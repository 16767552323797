import {Dispatch, SetStateAction} from "react";
import {Socket} from "socket.io-client";

export type UserInfoProps = {
  displayName: string | null;
  givenName: string | null;
  id: string | null;
  jobTitle: string | null;
  mail: string | null;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string | null;
  surname: string | null;
  userPrincipalName: string;
} | null;

export type CreateUserProps = {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  mobilePhone: string;
  jobTitle: string;
  birthday: string;
  profileImage: File | null;
};

export type NotificationListProps = {
  notificationId?: number;
  userId?: number;
  postId?: number;
  content?: string;
  isRead?: boolean;
};

export type NotificationToggleProps = {
  setToggle?: Dispatch<SetStateAction<boolean>>;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedPostNotification?: any;
  setSelectedNotificationId?: any;
};

export type NavigationProps = {
  socket?: Socket | null;
  notificationToggle?: boolean;
  setNotificationToogle?: Dispatch<SetStateAction<boolean>>;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
  setSelectedNotificationId?: Dispatch<SetStateAction<number | null>>;
};

export type NotificationModalProps = {
  notificationModal?: boolean;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  selectedPostNotification?: number | null;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
  selectedNotificationId?: number | null;
  socket?: Socket | null;
};

export type AvatarNotificationProps = {
  avatarToggle?: boolean;
  setAvatarToogle?: Dispatch<SetStateAction<boolean>>;
};

export type NotificationSenderProps = {
  senderId: number;
}[];

export type LoginUserProps = {
  email: string;
};

export type LevelBlockProps = {
  data: {
    success: boolean;
    points: {
      pointsId: number;
      userId: number;
      coins?: number;
      points: number;
      giveablePoints: number;
    };
  };
};

export type ErrorProps = {
  message?: string;
} | null;

export type LeaderBoardUserProps = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type LeaderBoardUserPointProps = {
  pointsId: number;
  userId: number;
  points: number;
  user: LeaderBoardUserProps;
};

export type LeaderBoardCardProps = {
  data: LeaderBoardUserPointProps;
};

export type LeaderBoardListProps = {
  success: boolean;
  users: LeaderBoardUserPointProps[];
};

export type LeaderBoardProps = {
  leaderBoardList: any;
  leaderBoardListLoading: boolean;
};

export type CreatePostType = {
  message: string;
  imagePost: File | null;
  pointsGiven: number | null;
  recipients?: CreateUserProps[] | null;
  hashTagIds?: {}[] | null;
};

export type EditCreatePostTypeProps = {
  message?: string;
  imagePost?: File | null;
  hashTagIds?: {}[] | null;
};

export type CreateBadgePostType = {
  badgeName: string;
  badgeImage: File | null;
  message: string;
  points: number | null;
  frequency: string | null;
  hashTagIds: {}[] | null;
};

export type CreateBadgePostProps = {
  message?: string;
  pointsGiven?: number;
  hashTagIds?: {}[] | null;
  recipients?: CreateUserProps[] | null;
} | null;

export type PostBlockProps = {
  postLoading?: boolean;
  data?: {
    success: boolean;
    points: {
      pointsId: number;
      userId: number;
      points: number;
      giveablePoints: number;
    };
  };
  onChangeHandler?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onSubmitHandler?: (e: React.FormEvent<HTMLFormElement>) => void;
  formPost?: CreatePostType;
  postSuccessTrigger?: boolean;
  setAmountShowDropDown?: Dispatch<SetStateAction<boolean>>;
  setFormPost?: Dispatch<SetStateAction<CreatePostType>> | null;
  filteredUsers?: RecipientListProps[];
  setFilteredUsers?: Dispatch<SetStateAction<RecipientListProps[]>>;
  emptyValidation?: boolean;
  setEmptyValidation?: Dispatch<SetStateAction<boolean>>;
  emptyPoints?: boolean;
  setEmptyPoints?: Dispatch<SetStateAction<boolean>>;
  exceedPointsValidation?: boolean;
  setExceedPointsValidation?: Dispatch<SetStateAction<boolean>>;
  selectedHashTag?: SelectedHashTagField;
  setSelectedHashTag?: Dispatch<SetStateAction<SelectedHashTagField>>;
  imageValidation?: string | null;
  setImageValidation?: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedNumber?: React.Dispatch<React.SetStateAction<number | null>>;
};

export type RecipientModalProps = {
  formPost?: CreatePostType;
  recipientModal?: boolean;
  setFormPost?: Dispatch<SetStateAction<CreatePostType>> | null;
  setRecipientModal?: Dispatch<SetStateAction<boolean>>;
  filteredUsers?: RecipientListProps[];
  setFilteredUsers?: Dispatch<SetStateAction<RecipientListProps[]>>;
};

export type DatePickerComponentProps = {
  setFormInput?: Dispatch<SetStateAction<any>>;
  birthday?: string;
};

export type ExtendedPostBlockProps = PostBlockProps & {
  filteredUsers?: RecipientListProps[];
  setFilteredUsers?: Dispatch<SetStateAction<RecipientListProps[]>>;
  setRecipientModal?: Dispatch<SetStateAction<boolean>>;
};

export type LikesModalProps = {
  post: {
    postId?: number;
    likes?: {
      email?: string;
      preferredName?: string;
      pronouns?: string;
      reaction?: string;
    }[];
    userId?: number;
  };
  setSelectedCardModal?: Dispatch<SetStateAction<boolean>>;
};

export type CommentsModalProps = {
  post: {
    userId?: number;
    postId?: number;
    message?: string;
    createdAt?: string | Date;
    preferredName?: string;
    pronouns?: string;
    pointsGiven?: number;
    user?: {
      preferredName?: string;
      user_profile?: {
        pronouns?: string;
      };
    };
  };
  setCommentSection?: Dispatch<SetStateAction<boolean>>;
};

export type LikesDataProps = {
  id: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  pronouns: string;
  email: string;
  user_profile: {
    pronouns: string;
  };
};

export type AvatarLikeProps = {
  userId: number;
};

export type AmountModalProps = {
  giveablePoints?: number;
  formPost?: CreatePostType;
  amountModal?: boolean;
  setAmountModal?: Dispatch<SetStateAction<boolean>>;
  setFormPost?: Dispatch<SetStateAction<CreatePostType>> | null;
};

export type HashTagModalProps = {
  formPost?: CreatePostType;
  hashTagModal?: boolean;
  setFormPost?: Dispatch<SetStateAction<CreatePostType>> | null;
  setHashTagModal?: Dispatch<SetStateAction<boolean>>;
  selectedHashTag?: HashTagListProps[] | null;
  setSelectedHashTag?: Dispatch<SetStateAction<HashTagListProps[]>> | null;
};

export type HashTagPage = {
  hashTags: HashTagListProps[];
};

export type PostCardDetailsProps = {
  userId: number;
  postId: number;
  message: string;
  createdAt: string;
  imageURL: string;
  recipients: PostPropsPostCard[];
  hashTagIds?: {}[] | null;
  pointsGiven: number;
  likes: any;
  badgeId?: number;
  user: {
    preferredName: string;
    email: string;
    isAdmin: number;
    user_profile: {
      pronouns: string;
    };
    user_point?: {
      points?: number;
    };
  };
};

export type PostCardProps = {
  post: PostCardDetailsProps;
  socket?: Socket | null;
  setSelectedCard?: React.Dispatch<React.SetStateAction<{}>>;
  setSelectedCardModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPostModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditCardSelection?: React.Dispatch<React.SetStateAction<number | null>>;
};

export type PostCardFooterProps = {
  post: {
    userId?: number;
    postId?: number;
    message?: string;
    likes?: [];
    createdAt?: string | Date;
    preferredName?: string;
    pronouns?: string;
    pointsGiven?: number;
    user?: {
      preferredName?: string;
      user_profile?: {
        pronouns?: string;
      };
    };
  };
  index?: number;
  socket?: Socket | null;
};

export type TeamBlockProps = {
  id: number;
  preferredName: string;
  email: string;
  profileImage: string;
  user_profile: {
    pronouns: string;
  };
};

export type LikeButtonProps = {
  post: {
    postId?: number;
    userId?: number;
    likes?: PostUserProps[];
  };
  isLiked?: boolean;
  onLikeButtonClickHandler?: (index: number) => void;
  likeIsLoading?: boolean;
  userDetails: any;
};

export type PostUserProps = {
  id?: number;
  email: string;
  reaction?: string;
};

export type RecipientListProps = {
  id: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
};

export type PostCardMessageProps = {
  data: {
    userId: number;
    postId: number;
    message: string;
    createdAt: string;
  };
};

export type PostCardMessageSectionProps = {
  message: string;
  recipients: PostPropsPostCard[];
  hashTagIds?: {}[] | null;
  badgeId?: number;
  badgeData?: {
    badgeId?: number;
    imageURL?: string;
    badgeName?: string;
  };
  badgeDataLoading?: boolean;
};

export type LeaderboardUpdatedProps = {
  user?: {
    pointsId?: number;
    userId?: number;
    points?: number;
    giveablePoints?: number;
    user?: TeamBlockProps;
    profileImageURL?: string;
  };
};

export type PostPropsPostCard = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  preferredName: string;
};

export type PostCardHeaderSectionProp = {
  userId: number;
  postId: number;
  dateCreated: string;
  preferredName: string;
  pronouns: string;
  pointsGiven: number;
  badgeId?: number;
  isAdmin?: number;
  points?: {
    points?: number;
  };
  setEditPostModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditCardSelection?: React.Dispatch<React.SetStateAction<number | null>>;
};

export type EditPostModalProps = {
  setEditPostModal?: React.Dispatch<React.SetStateAction<boolean>>;
  editCardSelection?: number | null;
  setEditCardSelection?: React.Dispatch<React.SetStateAction<number | null>>;
  setPostListLoading?: any;
};

export type PostCardDropDownProps = {
  postId: number;
  setEditPostModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditCardSelection?: React.Dispatch<React.SetStateAction<number | null>>;
};

export type TeamPersonalDetailsProps = {
  userId?: number;
};

export type SelectedUserPersonalDetailsProps = {
  user?: {
    id?: number;
    profileImage?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    jobTitle?: string;
    mobilePhone?: string;
    user_profile?: {
      birthday?: string;
      profileId?: number;
      userId?: number;
      pronouns?: string;
      bio?: string;
      city?: string;
      province?: string;
      country?: string;
    };
    user_point?: {
      pointsId?: number;
      userId?: number;
      points?: number;
      giveablePoints?: number;
    };
  };
  profileImage: string | null;
} | null;

export type AccessTokenProps = {
  id: number;
  isAdmin: number;
  email: string;
} | null;

export type TokenPayload = {
  id?: number;
  email: string;
  isAdmin: number;
  firstName?: string;
  lastName?: string;
};

export type AccessTokenErrorProps = {
  message: string;
};

export type ModalProps = {
  modalOpen: boolean;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  onSubmitHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
};

export type LevelInfo = {
  level: LevelName | null;
  index: number | null;
  percentage: number | null;
};

export type ProgressBarProps = {
  width: number;
  points: number;
};

export type CommentPostFooterProps = {
  message?: string;
  post?: {
    postId?: number;
    userId?: number;
    user?: {
      preferredName?: string;
    };
  };
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  socket?: Socket | null;
};

export type CommentPostProps = {
  message?: string;
  commentId?: number;
};

export type CommentButtonProps = {
  post?: {
    postId?: number;
  };
};

export type CommentPostCardListProps = {
  comments: {
    postId?: number;
    userId?: number;
    commentId?: number;
    createdAt?: string;
    message?: string;
  };
};

export type CommentPostCardProps = {
  data: {
    commentId?: number;
    userId?: number;
    postId?: number;
    createdAt?: string;
    message?: string;
    preferredName?: string;
    pronouns?: string | null;
    userPoints?: number | null;
    isAdmin?: number;
  };
};

export type CommentModalUpdateProps = {
  post?: {
    userId?: number;
    postId?: number;
    commentId?: number;
    user?: {
      preferredName?: string;
      user_profile?: {
        pronouns?: string;
      };
    };
  };
  setCommentSection?: React.Dispatch<React.SetStateAction<boolean>>;
  onEditClickHandler?: () => void;
};

export type EditProfileProps = {
  preferredName?: string;
  pronouns?: string;
  bio?: string;
  city?: string;
  province?: string;
  country?: string;
  birthday?: string;
};

export type CustomSelectOption = {
  value: string;
  label: string;
};

export type CustomSelectProps = {
  options: CustomSelectOption[];
  onSelect: (option: CustomSelectOption) => void;
  placeholder: string;
  selectedPronoun?: string;
};

export type CustomSelectHashTagType = {
  hashTagId?: number | null;
  hashTagName?: string;
  postCount?: number;
};

export type CustomSelectHashTagProps = {
  options?: CustomSelectHashTagType[];
  onSelect?: (option: CustomSelectHashTagType) => void;
  placeholder?: string;
  selectedHashTag?: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
};

export type TeamProfileDetailsProps = {
  userId?: number;
  showDetails?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export type LogoProps = {
  addClassName?: string;
};

export type BadgeModalProps = {
  setBadgeModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CreateBadgeProps = {
  badgeName?: string;
  badgeImage?: File | null;
  points?: number | null | string;
  frequency?: string;
  fixedPoints?: string;
  hashTags?: string;
};

export type CreateHashTagFormProps = {
  hashTagName?: string;
};

export type CreateHashTagModalProps = {
  setCreateHashTagModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type SelectedHashTagField = {
  hashTagId?: number;
  hashTagName?: string;
  postCount?: number;
  createdAt?: string;
}[];

export type HashTagListProps = {
  hashTagId?: number;
  hashTagName?: string;
  postCount?: number;
  createdAt?: string;
};

export type HashTagPropsCardList = {
  hashTagId?: number;
  hashTagName?: string;
};

export type AdminCreateBadgeProps = {
  badgeId?: number;
  badgeName?: string;
  imageURL?: string;
  coins?: string;
  points?: number;
  frequency?: string;
  hashTagIds?: {}[] | null;
  message?: string;
};

export type BadgeUpdateProps = {
  setIsBadgeListLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBadge?: number | null;
  setModals: React.Dispatch<
    React.SetStateAction<{
      edit: boolean;
      delete: boolean;
      loading: boolean;
      update: boolean;
      badge: boolean;
    }>
  >;
};

export type RecognitionBlockProps = {
  setModalRecognition?: React.Dispatch<React.SetStateAction<boolean>>;
  socket?: Socket | null;
};

export type SteOneProps = {
  onCloseClickHandler?: () => void;
  selectedBadge?: AdminCreateBadgeProps | null;
  onBadgeSelectClickHandler: (badge: AdminCreateBadgeProps) => void;
  stepTwoOnClickHandler: () => void;
  stepOneErrorMessage?: boolean;
};

export type StepTwoProps = {
  onCloseClickHandler?: () => void;
  selectedBadge?: AdminCreateBadgeProps | null;
  onBadgeSelectClickHandler: (badge: AdminCreateBadgeProps) => void;
  stepTwoOnClickHandler: () => void;
  setSelectBadgeStepOne?: React.Dispatch<React.SetStateAction<boolean>>;
  setGiveBadgeStepTwo?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalRecognition?: React.Dispatch<React.SetStateAction<boolean>>;
  socket?: Socket | null;
};

export type BadgeArrayProps = {
  badges: AdminCreateBadgeProps[];
};

export type GetAllUsersProps = {
  id?: number;
  email?: string;
  preferredName?: string;
};

export type RecepientBadgeButtonProps = {
  setRecipientList?: any;
};

export type BadgePropsDetails = {
  badgeId?: number;
  badgeName?: string;
  imageURL?: string;
  badgeImage?: string;
};

export type NotificationContextTypeProps = {
  userDetails?: {};
  socketState?: Socket | null;
  setNotificationModal?: Dispatch<SetStateAction<boolean>>;
  setSelectedNotificationId?: Dispatch<SetStateAction<number | null>>;
  setSelectedPostNotification?: Dispatch<SetStateAction<number | null>>;
};

export type UserTabDetailsProps = {
  userDetails: {
    id: number;
  };
};

export type IxieChatboxProps = {
  userDetails: {
    email: string;
  };
};

export type ProductProps = {
  productId?: string;
  type?: string;
  productName: string;
  productImage?: File | null;
  imageURL?: string;
  price: number | null;
  quantity: number | null;
  addOns: any;
  variant: any;
  category: string;
  productDescription: string;
};

export type AddOnProps = {
  addonId: number;
  addonName: string;
  addonImage?: File | null;
  price: number | null;
  addonDescription: string;
};

export type CreateProductModalProps = {
  onCloseClickHandler: () => void;
  createProductSubmitHandler: () => void;
  updateProductionSubmitHandler?: () => void;
  createProductIsLoading: boolean;
  inputChangeHandler: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | {target: {name: string; value: any}}
  ) => void;
  createProductForm: {
    productImage: File | null;
    type: string;
    productName: string;
    price: number | null;
    addOns: AddOnProps[];
    variant: {}[] | null;
    quantity: number | null;
    category: string;
    productDescription: string;
  };
  errorHandler: {
    success: boolean;
    message: string;
    field: string;
  };
  setCreateProductForm: React.Dispatch<React.SetStateAction<any>>;
  activeProductId?: number | null;
  toggleState: boolean;
  setToggleState: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CreateAddOnFormType = {
  addonImage: File | null;
  addonName: string;
  addonDescription: string;
  price: number;
};

export type ErrorHandlerAddOn = {
  success: boolean;
  message: string;
  field: string;
};

export type CreateAddOnModalProps = {
  createAddOnSubmitHandler: () => void;
  createAddOnIsLoading: boolean;
  createAddOnForm: CreateAddOnFormType;
  inputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseClickHandler: () => void;
  errorHandler: ErrorHandlerAddOn;
  setCreateAddOnForm: React.Dispatch<React.SetStateAction<CreateAddOnFormType>>;
};

export type UpdateAddOnModalProps = {
  updateAddOnSubmitHandler: () => void;
  createAddOnIsLoading: boolean;
  createAddOnForm: CreateAddOnFormType;
  inputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseClickHandler: () => void;
  errorHandler: ErrorHandlerAddOn;
  activeProductId?: number | null;
  setCreateAddOnForm: React.Dispatch<React.SetStateAction<CreateAddOnFormType>>;
};

export type Option = {
  id: string;
  title: string;
};

export type OrderProps = {
  fullName: string;
  orderId?: number;
  email: string;
  mobilePhone: string;
  city: string;
  province: string;
  postalCode: string;
  landmarks: string;
  orderDetails: {
    addOns?: AddOnProps[];
    variant?: Record<string, Option>;
    [key: string]: any;
  } | null;
  createdAt?: Date;
  orderStatus?: number;
};

/* ------ ENUMS ------ */

export enum LevelName {
  AsteroidAdventurer = "Asteroid Adventurer",
  CosmicCatalyst = "Cosmic Catalyst",
  ExtraterrestrialExplorer = "Extraterrestrial Explorer",
  FusionForerunner = "Fusion Forerunner",
  GalacticGroundbreaker = "Galactic Groundbreaker",
  InterplanetaryIconoclast = "Interplanetary Iconoclast",
  InterstellarInitiator = "Interstellar Initiator",
  IntergalacticInnovator = "Intergalactic Innovator",
  IntercelestialInventor = "Inter-celestial Inventor",
  LunarLeader = "Lunar Leader",
  LightyearLuminary = "Light-year Luminary",
  NebularNavigator = "Nebular Navigator",
  PlanetaryPathfinder = "Planetary Pathfinder",
  PulsarPioneer = "Pulsar Pioneer",
  StardustTrailblazer = "Stardust Trailblazer",
  StellarTrendsetter = "Stellar Trendsetter",
  SupernovaVanguard = "Supernova Vanguard",
  UltravioletVisionary = "Ultraviolet Visionary",
  ZeroGWayfarer = "Zero-G Wayfarer",
}
