import SummaryStyles from "./SummaryStyles.module.css";
import WalletIcon from "../../assets/img/navicons/wallet-icon.png";
import {LevelBlockProps} from "../../types/ComponentProps";

const Coins = ({data}: LevelBlockProps) => {
  return (
    <div className={`${SummaryStyles.coinsBlk}`}>
      <img alt="Coins Icon" src={WalletIcon} />
      <p>Total Coins in Wallet</p>
      <span>{data?.points?.coins}</span>
    </div>
  );
};

export default Coins;
