import {SetStateAction, Dispatch} from "react";
import Spinner from "../spinner/Spinner";
import ModalIcon from "../../assets/img/dashboard/modal-icon.png";
import AddOnsListStyles from "./AddOnsListStyles.module.css";
type DeleteProps = {
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  onDeleteSubmitHandler: () => void;
  createAddOnIsLoading?: boolean;
};

const DeleteAddOnModal = ({
  setShowDeleteModal,
  onDeleteSubmitHandler,
  createAddOnIsLoading,
}: DeleteProps) => {
  return (
    <div className={AddOnsListStyles.addOnDeletetModal}>
      <div className={AddOnsListStyles.deletePrompt}>
        <div className={AddOnsListStyles.deletePromptMainContainer}>
          <span
            className={`${AddOnsListStyles.xmark} fa-solid fa-xmark`}
            onClick={(e: any) => {
              if (createAddOnIsLoading) {
                return e.preventDefault();
              }
              setShowDeleteModal(false);
            }}></span>
          <img
            src={ModalIcon}
            alt="Modal icon"
            className={AddOnsListStyles.deleteIcon}
          />
          <h1>Are you sure you want to delete this add-on?</h1>
          <button onClick={onDeleteSubmitHandler}>
            {createAddOnIsLoading ? (
              <Spinner variant="small-spinner" alignSpin="center-spinner" />
            ) : (
              "YES"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAddOnModal;
