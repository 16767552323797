import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import useGetAllOrders from "../../hooks/useGetAllOrders";
import Spinner from "../spinner/Spinner";
import {OrderProps} from "../../types/ComponentProps";
import OrderStatusDropDown from "../orderStatusDropDown/OrderStatusDropDown";
import OderListStyles from "./OrderListStyles.module.css";

const OrderList = () => {
  const {ref, inView} = useInView();
  const {data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage} =
    useGetAllOrders();
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  const [expandedAddresses, setExpandedAddresses] = useState<
    Record<number, boolean>
  >({});
  const orderList = data?.pages.flatMap((data: any) => data.orders) || [];

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleAddress = (index: number) => {
    setExpandedAddresses((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <div className={OderListStyles.manageAddOnListBlk}>
      <div
        className={OderListStyles.manageProductBlkTitle}
        style={
          orderList.length > 10 ? {overflowY: "scroll"} : {overflowY: "hidden"}
        }>
        <h1>Orders</h1>
      </div>
      {/* Table Headers */}
      <div className={OderListStyles.headerTableBlkHeaderAddOnList}>
        {[
          "Order No.",
          "Type",
          "Employee Name",
          "Product Details",
          "Email",
          "Amount",
          "Date",
          "Address",
          "Status",
        ].map((header) => (
          <div key={header}>
            <p>{header}</p>
          </div>
        ))}
      </div>
      {/* Product List */}
      <div className={OderListStyles.addOnListMainContainer}>
        {isLoading ? (
          <div style={{display: "block", padding: "10px 0"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : orderList.length === 0 ? (
          <p className={OderListStyles.notAvailableBlkTxt}>
            No orders are currently available
          </p>
        ) : (
          orderList.map((order: OrderProps, index: number) => (
            <div className={OderListStyles.rowBlkAddOnList} key={index}>
              <div>
                <p>{order?.orderId}</p>
              </div>
              <div>
                <p>{order?.orderDetails?.type}</p>
              </div>
              <div>
                <p>{order?.fullName}</p>
              </div>
              <div
                className={OderListStyles.cellCollapsibleSection}
                onClick={() => toggleRow(index)}
                style={{cursor: "pointer"}}>
                <p>
                  {expandedRows[index] ? (
                    <div>
                      {order?.orderDetails?.productName && (
                        <>
                          <b>Product Name:</b>{" "}
                          {order?.orderDetails?.productName}
                        </>
                      )}
                    </div>
                  ) : (
                    `${order?.orderDetails?.productName.substring(0, 20)}...`
                  )}
                </p>

                <i
                  className={`fa-solid ${
                    expandedRows[index] ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                  style={{marginLeft: "10px"}}
                />
              </div>

              <div>
                <p>{order?.email}</p>
              </div>
              <div>
                <p>{order?.orderDetails?.price}</p>
              </div>
              <div>
                <div>
                  <p>
                    {order?.createdAt
                      ? new Date(order.createdAt).toLocaleDateString()
                      : "-"}
                  </p>
                </div>
              </div>
              <div
                className={OderListStyles.cellCollapsibleSection}
                onClick={() => toggleAddress(index)}
                style={{cursor: "pointer"}}>
                <p>
                  {expandedAddresses[index]
                    ? `${order?.city} ${order?.province} ${
                        order?.postalCode
                      } ${order?.landmarks} `
                    : `${order?.city} ${order?.province}...`}
                </p>

                <i
                  className={`fa-solid ${
                    expandedAddresses[index]
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                  style={{marginLeft: "10px"}}
                />
              </div>
              <div>
                <OrderStatusDropDown order={order} />
              </div>
            </div>
          ))
        )}
        <div ref={ref} style={{height: "1px"}}></div>
        {isFetchingNextPage && (
          <div style={{display: "block", padding: "20px 0px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderList;
