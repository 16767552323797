import {useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import HashTagIcon from "../../assets/img/navicons/hashTag-icon.png";
import ProductIcon from "../../assets/img/navicons/shop-icon.svg";
import RecognitionImage from "../../assets/img/navicons/recognition-icon.png";
import Logo from "../../components/logo/Logo";
import {useEffect, useState} from "react";

const NavLinksAdmin = () => {
  const location = useLocation();
  const [shopDropDown, setDropDown] = useState(false);

  const shopPaths = useMemo(
    () => ["/create-product", "/create-addons", "/orders"],
    []
  );
  useEffect(() => {
    setDropDown(shopPaths.includes(location.pathname));
  }, [location.pathname, shopPaths]);

  const onClickShop = () => {
    setDropDown((previous) => !previous);
  };

  return (
    <div className="navlinks-create-badge">
      <Logo addClassName="add-margin-logo" />
      <div
        className={`create-badge-link ${
          location.pathname === "/create-badge" ? "active-nav-admin" : null
        }`}>
        <Link to="/create-badge">
          <div className="flex-box-blk">
            <img src={RecognitionImage} alt="Recognition" />
            <p>Manage Badges</p>
          </div>
        </Link>
      </div>

      <div
        className={`create-badge-link ${
          location.pathname === "/create-hashTag" ? "active-nav-admin" : null
        }`}>
        <Link to="/create-hashTag">
          <div className="flex-box-blk">
            <img src={HashTagIcon} alt="User" />
            <p>Manage HashTags</p>
          </div>
        </Link>
      </div>

      <div
        className={`nav-parent-link ${shopDropDown ? "active-nav" : null}`}
        onClick={onClickShop}>
        <div className="flex-box-blk header-text-nav-parent-blk">
          <img src={ProductIcon} alt="Product" />
          <p>Shop</p>
          <i
            className={`fa-solid fa-chevron-${
              shopDropDown ? "up" : "down"
            }`}></i>
        </div>

        <div className="header-link-list-nav-parent-blk">
          <Link
            to="/create-product"
            className={
              location.pathname === "/create-product"
                ? "active-admin-link-blk"
                : ""
            }>
            Products
          </Link>
          <Link
            to="/create-addons"
            className={
              location.pathname === "/create-addons"
                ? "active-admin-link-blk"
                : ""
            }>
            Add ons
          </Link>
          <Link
            to="/orders"
            className={
              location.pathname === "/orders" ? "active-admin-link-blk" : ""
            }>
            Orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavLinksAdmin;
