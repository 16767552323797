import {useQuery} from "@tanstack/react-query";
import getAddOnById from "../api/getAddOnById";

const useGetAddOnById = (addOnId: number) => {
  const {
    data: getAddOnByIdData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getAddOnById", addOnId],
    queryFn: () => getAddOnById(addOnId),
    enabled: addOnId > 0,
    refetchOnWindowFocus: false,
  });
  return {isLoading, getAddOnByIdData, isError, error};
};

export default useGetAddOnById;
