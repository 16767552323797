import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {likeAPost, unLikeAPost} from "../../api/likeAPost";
import LikeButton from "../likeButton/LikeButton";
import CommentButton from "../commentButton/CommentButton";
import {
  PostCardFooterProps,
  TokenPayload,
  PostUserProps,
} from "../../types/ComponentProps";
import PostCardStyles from "./PostCardStyles.module.css";

const PostCardFooter = ({post, socket}: PostCardFooterProps) => {
  const {userId} = useParams();
  const queryClient = useQueryClient();
  const [likeIsLoading, setLikeIsLoading] = useState<boolean>(false);

  const userDetails: TokenPayload = jwtDecode(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );

  const isLiked = post?.likes?.some((user: PostUserProps) => {
    return Number(user?.id) === Number(userDetails?.id);
  });

  const onClickHandlerLikeIO = () => {
    socket?.emit("sendLikeNotification", {
      senderDetails: userDetails,
      receiverDetails: post,
    });
  };

  // Like Mutation
  const likeAPostMutation = useMutation({
    mutationFn: likeAPost,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPostById", post?.postId && Number(post?.postId)],
      });
      await queryClient.invalidateQueries({queryKey: ["getAllPosts"]});

      await queryClient.invalidateQueries({
        queryKey: [
          "getAllPostsById",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });

      await queryClient.invalidateQueries({
        queryKey: [
          "userLikedPosts",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });

      await queryClient.invalidateQueries({
        queryKey: [
          "allMentionedPost",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });

      setLikeIsLoading(false);
    },
    onError: (error: Error) => {
      console.error("Error liking post:", error.message);
      setLikeIsLoading(false);
    },
  });

  // Unlike Mutation
  const unLikeAPostMutation = useMutation({
    mutationFn: unLikeAPost,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ["getAllPosts"]});
      await queryClient.invalidateQueries({
        queryKey: [
          "getAllPostsById",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: [
          "userLikedPosts",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });

      await queryClient.invalidateQueries({
        queryKey: [
          "allMentionedPost",
          userId ? Number(userId) : Number(userDetails?.id),
        ],
      });

      await queryClient.invalidateQueries({
        queryKey: ["getPostById", post?.postId && Number(post?.postId)],
      });
      setLikeIsLoading(false);
    },
    onError: (error: Error) => {
      console.error("Error unliking post:", error.message);
    },
  });

  const onLikeButtonClickHandler = async (index: number) => {
    setLikeIsLoading(true);
    try {
      if (!isLiked) {
        await likeAPostMutation.mutateAsync({post, index});
        onClickHandlerLikeIO();
      } else {
        await unLikeAPostMutation.mutateAsync({post, index});
      }
    } catch (error) {
      console.error("Error during like/unlike mutation:", error);
    } finally {
      setLikeIsLoading(false);
    }
  };

  return (
    <>
      <div className={PostCardStyles.footerSectionStyles}>
        <div>
          <CommentButton post={post} />
        </div>
        <div>
          <LikeButton
            onLikeButtonClickHandler={onLikeButtonClickHandler}
            post={post}
            likeIsLoading={likeIsLoading}
            isLiked={isLiked}
            userDetails={userDetails}
          />
        </div>
      </div>
    </>
  );
};

export default PostCardFooter;
