import HashTagList from "../../components/hashTagList/HashTagList";
import NavLinksAdmin from "../../components/navlinksAdmin/NavLinksAdmin";

const AdminCreateHashTag = () => {
  return (
    <>
      <div className="inside-page-container-blk">
        <div className="create-badge-main-container">
          <NavLinksAdmin />
          <div>
            <HashTagList />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreateHashTag;
