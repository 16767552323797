import axiosInstance from "./axiosInstance";
import {ProductProps} from "../types/ComponentProps";

const editOrderStatus = async (orderId: number, orderDetails: any) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  try {
    const response = await axiosInstance.patch(
      `/api/order/${orderId}`,
      {
        orderStatus: orderDetails.orderStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating user. Try");
  }
};

export default editOrderStatus;
