import {useState, useRef, useEffect} from "react";
import Spinner from "../spinner/Spinner";
import CustomSelectAddOnsStyles from "./CustomSelectAddOns.module.css";

const CustomSelectAddOns = ({placeholder, onSelect, options}: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (option: any) => {
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={CustomSelectAddOnsStyles.customSelect} ref={dropdownRef}>
      <div
        className={CustomSelectAddOnsStyles.selectDisplay}
        onClick={toggleDropdown}>
        <span>
          {options?.length === 0 ? "No Add-ons Available" : placeholder}
        </span>
        <i
          className={`${
            isOpen
              ? `fa-solid fa-chevron-up ${CustomSelectAddOnsStyles.icon}`
              : `fa-solid fa-chevron-down ${CustomSelectAddOnsStyles.icon}`
          }`}
        />
      </div>
      {isOpen && (
        <ul className={CustomSelectAddOnsStyles.optionList}>
          {options?.map((option: any, index: any) => (
            <li
              key={index}
              className={CustomSelectAddOnsStyles.optionItem}
              onClick={() => handleSelectOption(option)}>
              {option?.addonName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelectAddOns;
