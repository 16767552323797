import {useParams} from "react-router-dom";
import useGetUserDetailsById from "../../hooks/useGetUserDetailsById";
import {useNotification} from "../../context/NotificationContext";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import Navigation from "../../components/navigation/Navigation";
import Spinner from "../../components/spinner/Spinner";
import ProfilePublic from "../../components/publicProfile/ProfilePublic";

const PublicProfilePage = () => {
  const {userId} = useParams();
  const {userDetailsById} = useGetUserDetailsById(Number(userId));

  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();

  if (!socketState) {
    return (
      <>
        <Navigation />
        <div className="inside-page-container-blk">
          <div
            className="main-container-blk"
            style={{
              position: "relative",
              height: "100vh",
              paddingTop: "200px",
            }}>
            <Spinner alignSpin="center-spinner" variant="large-spinner" />
          </div>
        </div>
      </>
    );
  }

  return (
    <WithNavTemplate
      socket={!socketState ? null : socketState}
      setNotificationModal={setNotificationModal}
      setSelectedPostNotification={setSelectedPostNotification}
      setSelectedNotificationId={setSelectedNotificationId}>
      <div className="inside-page-container-blk profile-main-container">
        <div className="main-container-blk ">
          {userDetailsById && (
            <ProfilePublic user={userDetailsById} isLoading />
          )}
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default PublicProfilePage;
