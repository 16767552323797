import axiosInstance from "./axiosInstance";

type ProductDataProps = {
  addonName?: string;
  addonImage?: File | null;
  addonDescription?: string;
  price?: number | null;
};

const createAddOn = async (formData: ProductDataProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/createAddOn", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "Error creating post";
    throw new Error(errorMessage);
  }
};

export default createAddOn;
