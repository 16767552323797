import {Link, useLocation} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {TokenPayload} from "../../types/ComponentProps";
import HomeIcon from "../../assets/img/navicons/home-icon.svg";
import ShopIcon from "../../assets/img/navicons/shop-icon.svg";
import CareersIcon from "../../assets/img/navicons/careers-icon.svg";

import AdminLinksStyles from "./AdminLinks.module.css";

const AdminLinks = () => {
  const location = useLocation();
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );

  return (
    <div>
      <div className={`flex-box-blk ${AdminLinksStyles.flexBoxBlk}`}>
        <Link to="/" className={location.pathname === "/" ? "nav-active" : ""}>
          <img src={HomeIcon} alt="Home" />
          Home
        </Link>
        <Link
          to="/shop"
          className={location.pathname === "/shop" ? "nav-active" : ""}>
          <img src={ShopIcon} alt="Shop" />
          Shop
        </Link>
        <Link to="/careers">
          <img src={CareersIcon} alt="Careers" /> Careers
        </Link>
        {userDetails?.isAdmin ? (
          <Link to="/create-badge">
            Admin{" "}
            <i
              className="fa-solid fa-chevron-down"
              style={{marginLeft: "10px"}}
            />
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default AdminLinks;
