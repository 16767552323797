import axiosInstance from "./axiosInstance";

const deleteAddOn = async (addOnId: number) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  if (!accessToken) {
    throw new Error("No access token found");
  }

  try {
    const response = await axiosInstance.delete(`/api/addOn/${addOnId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    let errorMessage = "Error deleting Add-On. Try again.";

    if (error instanceof Error) {
      errorMessage = error.message;
    }

    if (typeof error === "object" && error !== null) {
      const err = error as {response?: {data?: {message?: string}}};
      errorMessage = err.response?.data?.message || errorMessage;
    }

    throw new Error(errorMessage);
  }
};

export default deleteAddOn;
