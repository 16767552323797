import {useState, useEffect, useRef} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import createProduct from "../../api/createProduct";
import deleteProduct from "../../api/deleteProduct";
import editProduct from "../../api/editProduct";
import useGetAllProducts from "../../hooks/useGetAllProducts";
import Spinner from "../spinner/Spinner";
import {ProductProps} from "../../types/ComponentProps";
import ProductListStyles from "./ProductListStyles.module.css";
import CreateProductModal from "./CreateProductModal";
import DeleteProductModal from "./DeleteProductModal";
import UpdateProductModal from "./UpdateProductModal";

// Modal State Type
type ModalState = {
  type: "create" | "edit" | "delete" | null;
  productId?: number;
  productCategory?: "Merch" | "Voucher" | null;
};

const ProductList: React.FC = () => {
  const queryClient = useQueryClient();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const {ref, inView} = useInView();

  const {
    getAllProductsData,
    isLoadingGetAllProducts,
    fetchNextPageGetAllProducts,
    hasNextPageGetAllProducts,
    isFetchingNextPageGetAllProducts,
  } = useGetAllProducts();

  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [modalState, setModalState] = useState<ModalState>({type: null});
  const [isRefetching, setIsRefetching] = useState(false);
  const [toggleState, setToggleState] = useState<boolean>(false);
  const [buttonCreateDropDown, setButtonCreateDropDown] = useState(false);

  const [createProductForm, setCreateProductForm] = useState({
    productName: "",
    type: "",
    productImage: null as File | null,
    price: null as number | null,
    quantity: null as number | null,
    addOns: [],
    variant: [],
    category: "",
    productDescription: "",
  });

  const [createProductIsLoading, setCreateProductIsLoading] = useState(false);
  const [errorHandler, setErrorHandler] = useState({
    success: true,
    message: "",
    field: "",
  });

  useEffect(() => {
    if (inView && hasNextPageGetAllProducts) {
      fetchNextPageGetAllProducts();
    }
  }, [inView, fetchNextPageGetAllProducts, hasNextPageGetAllProducts]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setButtonCreateDropDown(false);
      }
    };

    if (buttonCreateDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonCreateDropDown]);

  const truncateText = (text: string, limit: number) =>
    text.length > limit ? text.slice(0, limit) + "..." : text;

  const productList =
    getAllProductsData?.pages.flatMap((page: any) => page.products) || [];

  // React Query Mutations
  const createMutation = useMutation({
    mutationFn: () => {
      const hasInvalidVariant = createProductForm.variant.some(
        (variant: any) =>
          !variant.title.trim() ||
          variant.options.every((option: any) => !option.title.trim())
      );

      if (hasInvalidVariant) {
        setErrorHandler({
          success: false,
          message:
            "Each variant must have a title and at least one valid option.",
          field: "variant",
        });
        return Promise.reject(
          new Error(
            "Each variant must have a title and at least one valid option."
          )
        );
      }

      return createProduct(createProductForm);
    },
    onSuccess: async () => {
      setIsRefetching(true);
      await queryClient.invalidateQueries({queryKey: ["getAllProducts"]});
      setModalState({type: null});
      setCreateProductIsLoading(false);
      setIsRefetching(false);
      setCreateProductForm({
        productName: "",
        type: "",
        productImage: null,
        price: null,
        quantity: null,
        addOns: [],
        variant: [],
        category: "",
        productDescription: "",
      });
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateProductIsLoading(false);
      console.error("Error creating product:", error.message);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (productId: number) => deleteProduct(productId),
    onSuccess: async () => {
      setIsRefetching(true);
      await queryClient.invalidateQueries({queryKey: ["getAllProducts"]});
      setModalState({type: null});
      setCreateProductIsLoading(false);
      setIsRefetching(false);
      setCreateProductForm({
        productName: "",
        type: "",
        productImage: null,
        price: null,
        quantity: null,
        addOns: [],
        variant: [],
        category: "",
        productDescription: "",
      });
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateProductIsLoading(false);
      console.error("Error deleting product:", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (productId: number) =>
      editProduct(productId, createProductForm),
    onSuccess: async () => {
      setIsRefetching(true);
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ["getAllProducts"]}),
        queryClient.invalidateQueries({
          queryKey: ["getProductById", Number(modalState?.productId)],
        }),
      ]);
      handleCloseModal();
      setModalState({type: null});
      setCreateProductIsLoading(false);
      setIsRefetching(false);
    },
    onError: (error: Error) => {
      setIsRefetching(false);
      setCreateProductIsLoading(false);
      console.error("Error creating product:", error.message);
    },
  });

  // Handlers
  const createProductSubmitHandler = () => {
    const {type, addOns} = createProductForm;

    const requiredFieldsCommon: (keyof typeof createProductForm)[] = [
      "productImage",
      "productName",
      "type",
      "category",
      "quantity",
      "productDescription",
    ];

    const requiredFieldsMerch: (keyof typeof createProductForm)[] = [
      ...requiredFieldsCommon,
      "price",
    ];

    const requiredFields =
      type === "Merch" ? requiredFieldsMerch : requiredFieldsCommon;

    const missingField = requiredFields.find(
      (field) => !createProductForm[field]
    );

    if (missingField) {
      setErrorHandler({
        success: false,
        message: `${missingField.replace(/([A-Z])/g, " $1")} is required.`,
        field: missingField,
      });
      return;
    }

    if (toggleState && addOns.length === 0) {
      setErrorHandler({
        success: false,
        message: "You must select an Add-on.",
        field: "addOns",
      });
      return;
    }

    // Reset error state and proceed with submission
    setErrorHandler({success: true, message: "", field: ""});
    setCreateProductIsLoading(true);
    setToggleState(false);
    createMutation.mutate();
  };

  const updateProductionSubmitHandler = () => {
    const requiredFields: Array<keyof typeof createProductForm> = [
      "productName",
      "type",
      "category",
      "price",
      "quantity",
      "productDescription",
    ];

    for (const field of requiredFields) {
      if (!createProductForm[field]) {
        setErrorHandler({
          success: false,
          message: `${field.replace(/([A-Z])/g, " $1")} is required.`,
          field,
        });
        return;
      }
    }
    if (!modalState.productId) return;
    updateMutation.mutate(modalState?.productId);
    setErrorHandler({success: true, message: "", field: ""});
    setCreateProductIsLoading(true);
  };

  const onDeleteSubmitHandler = () => {
    if (modalState.type === "delete" && modalState.productId) {
      setCreateProductIsLoading(true);
      deleteMutation.mutate(modalState.productId);
    }
  };

  const onShowDropDownUpdateHandler = (productId: number) => {
    setActiveDropdown(activeDropdown === productId ? null : productId);
  };

  const handleCloseModal = () => {
    setCreateProductForm({
      productName: "",
      type: "",
      productImage: null as File | null,
      price: null as number | null,
      quantity: null as number | null,
      addOns: [],
      variant: [],
      category: "",
      productDescription: "",
    });
    setActiveDropdown(null);
    setToggleState(false);
    setErrorHandler({success: true, message: "", field: ""});
  };

  return (
    <div className={ProductListStyles.manageProductListBlk}>
      {/* Modals */}
      {modalState.type === "create" && (
        <CreateProductModal
          createProductSubmitHandler={createProductSubmitHandler}
          createProductIsLoading={createProductIsLoading}
          createProductForm={createProductForm}
          inputChangeHandler={(e) =>
            setCreateProductForm((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
          onCloseClickHandler={() => {
            handleCloseModal();
            setActiveDropdown(null);
            setModalState({type: null});
          }}
          errorHandler={errorHandler}
          setCreateProductForm={setCreateProductForm}
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
      )}

      {modalState.type === "delete" && modalState.productId && (
        <DeleteProductModal
          setShowDeleteModal={() => setModalState({type: null})}
          onDeleteSubmitHandler={onDeleteSubmitHandler}
          createProductIsLoading={createProductIsLoading}
        />
      )}

      {modalState.type === "edit" && (
        <UpdateProductModal
          activeProductId={modalState?.productId}
          updateProductionSubmitHandler={updateProductionSubmitHandler}
          createProductSubmitHandler={createProductSubmitHandler}
          createProductIsLoading={createProductIsLoading}
          createProductForm={createProductForm}
          inputChangeHandler={(e: any) =>
            setCreateProductForm((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
          onCloseClickHandler={() => {
            handleCloseModal();
            setActiveDropdown(null);
            setModalState({type: null});
          }}
          errorHandler={errorHandler}
          setCreateProductForm={setCreateProductForm}
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
      )}

      {/* Header */}
      <div
        className={ProductListStyles.manageProductBlkTitle}
        style={
          getAllProductsData?.pages[0]?.products?.length > 10
            ? {overflowY: "scroll"}
            : {}
        }>
        <h1>Products</h1>

        <div
          style={{marginLeft: "auto", position: "relative"}}
          ref={dropdownRef}>
          <button
            className={`${ProductListStyles.manageProductBlkTitleButton} ${
              buttonCreateDropDown ? ProductListStyles.buttonActive : ""
            }`}
            onClick={() => setButtonCreateDropDown((prev) => !prev)}>
            Add new product{" "}
            <i
              style={{marginLeft: "5px"}}
              className={`fa-solid ${
                buttonCreateDropDown ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </button>
          {buttonCreateDropDown && (
            <div className={ProductListStyles.dropDownCreate}>
              <span
                onClick={() => {
                  setModalState({type: "create", productCategory: "Merch"});
                  setCreateProductForm(() => ({
                    type: "Merch",
                    productName: "",
                    productImage: null,
                    price: null,
                    quantity: null,
                    addOns: [],
                    variant: [],
                    category: "",
                    productDescription: "",
                  }));
                  setButtonCreateDropDown(false);
                }}>
                Merch
              </span>
              <span
                onClick={() => {
                  setModalState({type: "create", productCategory: "Voucher"});
                  setCreateProductForm(() => ({
                    type: "Voucher",
                    productName: "",
                    productImage: null,
                    price: null,
                    quantity: null,
                    addOns: [],
                    variant: [],
                    category: "",
                    productDescription: "",
                  }));
                  setButtonCreateDropDown(false);
                }}>
                Voucher
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Table Headers */}
      <div className={ProductListStyles.headerTableBlkHeaderProductList}>
        {[
          "Product Image",
          "Product Name",
          "Type",
          "Category",
          "Price",
          "Quantity",
          "Description",
          "Actions",
        ].map((header) => (
          <div key={header}>
            <p>{header}</p>
          </div>
        ))}
      </div>

      {/* Product List */}
      <div className={ProductListStyles.productListMainContainer}>
        {isLoadingGetAllProducts || isRefetching ? (
          <div style={{display: "block", padding: "10px 0"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : productList.length === 0 ? (
          <p className={ProductListStyles.notAvailableBlkTxt}>
            No products are currently available
          </p>
        ) : (
          productList.map((product: ProductProps, index: number) => (
            <div className={ProductListStyles.rowBlkProductList} key={index}>
              <div>
                <img src={product.imageURL} alt={product.productName} />
              </div>
              <div>
                <p>{truncateText(product.productName, 20)}</p>
              </div>
              <div>
                <p>{product.type}</p>
              </div>
              <div>
                <p>{product.category}</p>
              </div>
              <div>
                <p>{product.price}</p>
              </div>
              <div>
                <p>{product.quantity}</p>
              </div>
              <div>
                <p>{truncateText(product.productDescription, 30)}</p>
              </div>
              <div>
                <div style={{position: "relative"}}>
                  {activeDropdown === Number(product.productId) && (
                    <div
                      className={ProductListStyles.dropDownEdit}
                      onMouseLeave={() => {
                        setActiveDropdown(null);
                      }}>
                      <span
                        onClick={() =>
                          setModalState({
                            type: "edit",
                            productId: Number(product.productId),
                          })
                        }>
                        Edit
                      </span>
                      <span
                        onClick={() =>
                          setModalState({
                            type: "delete",
                            productId: Number(product.productId),
                          })
                        }>
                        Delete
                      </span>
                    </div>
                  )}
                  <i
                    className="fa-solid fa-ellipsis"
                    onClick={() =>
                      onShowDropDownUpdateHandler(Number(product.productId))
                    }></i>
                </div>
              </div>
            </div>
          ))
        )}
        <div ref={ref} style={{height: "1px"}}></div>
        {isFetchingNextPageGetAllProducts && (
          <div style={{display: "block", padding: "20px 0px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
