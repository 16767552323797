import axiosInstance from "./axiosInstance";

type ProductDataProps = {
  productImage: File | null;
  productName: string;
  price: number | null;
  quantity: number | null;
  category: string;
  productDescription: string;
};

const createProduct = async (formData: ProductDataProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/createProduct", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "Error creating post";
    throw new Error(errorMessage);
  }
};

export default createProduct;
