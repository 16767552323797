import React from "react";
import InputStyles from "./InputStyles.module.css";

type InputProps = {
  type?: string;
  value?: string;
  name?: string;
  label?: string;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
};

const Input = ({
  type,
  value,
  name,
  label,
  onChangeHandler,
  placeholder,
  maxLength,
  disabled,
}: InputProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "number" && ["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (type === "number") {
      const pastedText = e.clipboardData.getData("Text");
      if (!/^\d+$/.test(pastedText)) {
        e.preventDefault(); // Block pasting if it contains non-numeric characters
      }
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;

    if (type === "number") {
      newValue = newValue.replace(/[^0-9]/g, ""); // Allow only numbers

      if (name === "price" && newValue.length > 6) {
        newValue = newValue.slice(0, 6);
      }
      if (name === "quantity" && newValue.length > 6) {
        newValue = newValue.slice(0, 6);
      }
      if (name === "mobilePhone" && newValue.length > 11) {
        newValue = newValue.slice(0, 11);
      }
      if (name === "postalCode" && newValue.length > 4) {
        newValue = newValue.slice(0, 4);
      }
      if (name === "verificationCode" && newValue.length > 6) {
        newValue = newValue.slice(0, 6);
      }

      e.currentTarget.value = newValue;
    }

    if (onChangeHandler) {
      onChangeHandler(e as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <>
      {label && (
        <label className={InputStyles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        className={InputStyles.input}
        type={type}
        value={value}
        name={name}
        maxLength={maxLength}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste} // ✅ Added onPaste event here
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
        disabled={disabled}
      />
    </>
  );
};

export default Input;