import axiosInstance from "./axiosInstance";
import {CreatePostType} from "../types/ComponentProps";

const createPost = async (formData: CreatePostType) => {
  const accessToken = localStorage.getItem("accessTokenGC");
  try {
    const data = await axiosInstance.post("/api/posts", formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "Error creating post";
    throw new Error(errorMessage);
  }
};

export default createPost;
