import {useState, useRef, useEffect} from "react";
import CustomSelectProductStyles from "./CustomSelectProductStyles.module.css";

type OptionType = {
  value: string;
  label: string;
};

type CustomSelectProductProps = {
  options: OptionType[];
  onSelect: (option: OptionType) => void;
  placeholder: string;
  value?: OptionType | null; // Added value prop
};

const CustomSelectProduct: React.FC<CustomSelectProductProps> = ({
  options,
  onSelect,
  placeholder,
  value, // Added value prop
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    value || null
  );
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleSelectOption = (option: OptionType) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOption(value || null); // Sync with external value changes
  }, [value]);

  return (
    <div className={CustomSelectProductStyles.customSelect} ref={dropdownRef}>
      <div
        className={CustomSelectProductStyles.selectDisplay}
        onClick={toggleDropdown}>
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        <i
          className={`fa-solid ${
            isOpen
              ? `fa-chevron-up ${CustomSelectProductStyles.icon}`
              : `fa-chevron-down ${CustomSelectProductStyles.icon}`
          }`}
        />
      </div>
      {isOpen && (
        <ul className={CustomSelectProductStyles.optionList}>
          {options.map((option) => (
            <li
              key={option.value}
              className={CustomSelectProductStyles.optionItem}
              onClick={() => handleSelectOption(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelectProduct;
