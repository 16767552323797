import {useState} from "react";
import {useDropzone} from "react-dropzone";
import Input from "../input/Input";
import Message from "../message/Message";
import Spinner from "../spinner/Spinner";
import ProductListStyles from "./ProductListStyles.module.css";
import {CreateProductModalProps, AddOnProps} from "../../types/ComponentProps";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import {categoryOptions} from "./dropdown";
import useGetAllAddOns from "../../hooks/useGetAllAddOns";
import CustomSelectAddOns from "../customSelectAddOns/CustomSelectAddOns";
import CustomSelectProduct from "../customSelectProduct/CustomSelectProduct";
import MerchVariant from "./MerchVariant";

const CreateProductModal = ({
  createProductSubmitHandler,
  onCloseClickHandler,
  inputChangeHandler,
  createProductForm,
  createProductIsLoading,
  errorHandler,
  setCreateProductForm,
  toggleState,
  setToggleState,
}: CreateProductModalProps) => {
  const [preview, setPreview] = useState<string | null>(null);
  const {getAllAddOnsData} = useGetAllAddOns();

  const {getRootProps, getInputProps} = useDropzone({
    accept: {"image/jpeg": [], "image/png": []},
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setPreview(URL.createObjectURL(file));
        inputChangeHandler({target: {name: "productImage", value: file}});
      }
    },
  });

  const handleSelectTypeAndCategory =
    (field: "type" | "category") =>
    (selectedOption: {value: string; label: string}) => {
      setCreateProductForm((prevForm: any) => {
        const updatedForm = {
          ...prevForm,
          [field]: selectedOption.value,
        };

        if (field === "type" && selectedOption.value === "Voucher") {
          setToggleState(false);
          updatedForm.addOns = [];
        }

        return updatedForm;
      });
    };

  const handleSelectAddOns = (selectedOption: AddOnProps) => {
    setCreateProductForm((prevForm: any) => {
      const addOnsArray = Array.isArray(prevForm.addOns) ? prevForm.addOns : [];

      const isDuplicate = addOnsArray.some(
        (addOn: AddOnProps) => addOn.addonId === selectedOption.addonId
      );

      if (isDuplicate) return prevForm;

      const updatedForm = {
        ...prevForm,
        addOns: [...addOnsArray, selectedOption],
      };

      return updatedForm;
    });
  };

  const removeListAddOns = (addonId: number) => {
    setCreateProductForm((prevForm: any) => {
      const updatedAddOns =
        prevForm.addOns?.filter(
          (addOn: AddOnProps) => addOn.addonId !== addonId
        ) || [];

      if (updatedAddOns.length === 0) {
        setToggleState(false);
      }

      return {
        ...prevForm,
        addOns: updatedAddOns,
      };
    });
  };

  const filteredAddOns =
    getAllAddOnsData?.pages
      .flatMap((page) => page?.addons)
      .filter(
        (addOn: AddOnProps) =>
          !createProductForm?.addOns.some(
            (selectedAddOn: AddOnProps) =>
              selectedAddOn?.addonId === addOn?.addonId
          )
      ) || [];

  return (
    <div className={ProductListStyles.createProductModal}>
      <div className={ProductListStyles.createProductModalMainContent}>
        <div className={ProductListStyles.flexBoxBlk}>
          <div>
            <h1>
              {createProductForm?.type === "Merch" && "Add New Merch Product"}
              {createProductForm?.type === "Voucher" && "Add New Voucher"}
            </h1>
          </div>
          <div>
            <button
              className={`fa-solid fa-xmark ${ProductListStyles.closeIconCreateBadge}`}
              onClick={onCloseClickHandler}
              disabled={createProductIsLoading}>Close</button>
          </div>
        </div>

        <hr className={ProductListStyles.horizontalLine} />
        <div className={ProductListStyles.scrollContainer}>
          <div className={ProductListStyles.formSection}>
            <h2 style={{marginTop: "20px"}}>Image</h2>
            <div {...getRootProps()} className={ProductListStyles.dropzone}>
              <input {...getInputProps()} />
              {preview ? (
                <div className={ProductListStyles.previewImageBlk}>
                  <img
                    src={preview}
                    alt="Preview"
                    className={ProductListStyles.imagePreview}
                  />
                </div>
              ) : (
                <div className={ProductListStyles.dragBoxBlk}>
                  <p>Choose a file or drag it here.</p>
                </div>
              )}
            </div>
            <h2>Basic Details</h2>

            {createProductForm?.type === "Merch" && (
              <div className={ProductListStyles.merchBlkContainer}>
                <div className={ProductListStyles.inputFlexBox}>
                  <div className={ProductListStyles.dropDownFlexContent}>
                    <Input
                      type="text"
                      label="Product Name"
                      onChangeHandler={inputChangeHandler}
                      name="productName"
                      value={createProductForm?.productName}
                      maxLength={150}
                    />
                    <div>
                      <p>Limited to 150 characters</p>
                    </div>
                  </div>
                  <div className={ProductListStyles.dropDownFlexContent}>
                    <label>Category</label>
                    <CustomSelectProduct
                      options={categoryOptions}
                      onSelect={handleSelectTypeAndCategory("category")}
                      placeholder="Select category"
                    />
                  </div>
                </div>
                <div className={ProductListStyles.inputFlexBox}>
                  <div>
                    <Input
                      type="number"
                      label="Price"
                      onChangeHandler={inputChangeHandler}
                      name="price"
                      maxLength={6}
                      value={
                        createProductForm?.price
                          ? createProductForm.price.toString()
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <Input
                      type="number"
                      label="Quantity"
                      onChangeHandler={inputChangeHandler}
                      name="quantity"
                      maxLength={6}
                      value={
                        createProductForm?.quantity !== null
                          ? createProductForm.quantity.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className={ProductListStyles.addOnSection}>
                  <div className={ProductListStyles.flexBoxBlk}>
                    <div>
                      <h1>Add ons</h1>
                    </div>
                    <div>
                      <ToggleSwitch
                        toggleState={toggleState}
                        setToggleState={setToggleState}
                        addOns={createProductForm?.addOns}
                      />
                    </div>
                  </div>
                  {toggleState && (
                    <>
                      <CustomSelectAddOns
                        options={filteredAddOns}
                        onSelect={handleSelectAddOns}
                        setCreateProductForm={setCreateProductForm}
                        placeholder="Select Add-Ons"
                      />
                      {createProductForm?.addOns.length === 0 ? null : (
                        <div className={ProductListStyles.addOnFlex}>
                          {createProductForm?.addOns.map(
                            (data: AddOnProps, index: number) => {
                              return (
                                <h1
                                  key={index}
                                  className={ProductListStyles.addOn}>
                                  <p>{data?.addonName} </p>
                                  <p
                                    onClick={() =>
                                      removeListAddOns(Number(data?.addonId))
                                    }>
                                    x
                                  </p>
                                </h1>
                              );
                            }
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <Input
                  type="text"
                  label="Description"
                  onChangeHandler={inputChangeHandler}
                  name="productDescription"
                  value={createProductForm?.productDescription}
                  maxLength={150}
                />
                    <div>
                      <p>Limited to 150 characters</p>
                    </div>
                <hr
                  className={ProductListStyles.horizontalLine}
                  style={{marginTop: "30px", marginBottom: "20px"}}
                />{" "}
                <MerchVariant
                  createProductForm={createProductForm}
                  setCreateProductForm={setCreateProductForm}
                />
              </div>
            )}

            {createProductForm?.type === "Voucher" && (
              <div className={ProductListStyles.voucherBlkContainer}>
                <div className={ProductListStyles.inputFlexBox}>
                  <div>
                    <Input
                      type="text"
                      label="Product Name"
                      onChangeHandler={inputChangeHandler}
                      name="productName"
                      value={createProductForm?.productName}
                    />
                  </div>

                  <div>
                    <Input
                      type="number"
                      label="Price"
                      onChangeHandler={inputChangeHandler}
                      name="price"
                      maxLength={6}
                      value={
                        createProductForm?.price
                          ? createProductForm.price.toString()
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className={ProductListStyles.inputFlexBox}>
                  <div>
                    <Input
                      type="number"
                      label="Quantity"
                      onChangeHandler={inputChangeHandler}
                      name="quantity"
                      maxLength={6}
                      value={
                        createProductForm?.quantity !== null
                          ? createProductForm.quantity.toString()
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <div className={ProductListStyles.dropDownFlexContent}>
                      <label>Category</label>
                      <CustomSelectProduct
                        options={categoryOptions}
                        onSelect={handleSelectTypeAndCategory("category")}
                        placeholder="Select category"
                      />
                    </div>
                  </div>
                </div>
                <Input
                  type="text"
                  label="Description"
                  onChangeHandler={inputChangeHandler}
                  name="productDescription"
                  value={createProductForm?.productDescription}
                />
              </div>
            )}
          </div>

          <hr
            className={ProductListStyles.horizontalLine}
            style={{marginTop: "20px", marginBottom: "20px"}}
          />
          {!errorHandler.success && errorHandler?.message ? (
            <Message variant="error">{errorHandler.message}</Message>
          ) : null}
          <button
            className={ProductListStyles.buttonCreateProduct}
            onClick={createProductSubmitHandler}
            disabled={createProductIsLoading}>
            {createProductIsLoading ? (
              <Spinner variant="small-spinner" alignSpin="center-spinner" />
            ) : (
              <>
                Add Product<i className="fa-solid fa-arrow-right"></i>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateProductModal;
