import axiosInstance from "./axiosInstance";
import {ProductProps} from "../types/ComponentProps";

const editProduct = async (productId: number, productDetails: ProductProps) => {
  const accessToken = localStorage.getItem("accessTokenGC");

  try {
    const response = await axiosInstance.patch(
      `/api/product/${productId}`,
      {
        productName: productDetails?.productName,
        productDescription: productDetails?.productDescription,
        type: productDetails?.type,
        productImage: productDetails?.productImage,
        quantity: productDetails?.quantity,
        price: productDetails?.price,
        addOns: productDetails?.addOns,
        category: productDetails?.category,
        variant: productDetails?.variant,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error updating user. Try");
  }
};

export default editProduct;
